export default {
  spanish: [
    "Cercanía",
    "Apertura al cambio",
    "Creatividad",
    "Integridad",
    "Trabajo en equipo",
    "Cooperación",
    "Innovación",
    "Tolerancia al fallo",
    "Respeto",
    "Confianza",
    "Orientación al Aprendizaje",

    "Liderazgo",
  ],

  english: [],
};
