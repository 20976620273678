<template>
  <v-sheet class="" color="transparent" width="100%">
    <!-- Static text and other buttons -->
    <v-row dense class="mt-5 d-flex justify-center">
      <v-col cols="12" md="10">
        <v-card class="d-flex justify-center text-center">
          <v-container>
            <!-- Title -->
            <v-row dense class="mt-3 d-flex justify-center hidden-sm-and-down">
              <v-col cols="10">
                <v-img
                  src="~@/assets/Teamtailor.png"
                  max-height="50px"
                  max-width="100%"
                  contain
                ></v-img>
              </v-col>
            </v-row>
            <v-card-text>
              <v-form v-model="form" @submit.prevent>
                <!-- Input -->
                <v-row dense class="mt-5">
                  <v-col cols="12">
                    <v-text-field
                      v-model="newtag"
                      required
                      autofocus
                      density="compact"
                      variant="solo"
                      class="newtag_tf"
                      type="text"
                      bg-color="#BDF0EE"
                      :placeholder="
                        integrationKey
                          ? `Integration Key asignada actualmente ->>> ${integrationKey}`
                          : 'Aún no has introducido tu Integration Key.'
                      "
                      :rules="newtagRules"
                      hide-details
                      single-line
                    >
                      <template v-slot:prepend-inner>
                        <v-icon color="#00C3C0">mdi mdi-label-multiple</v-icon>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- Buttons -->
                <v-row dense class="d-flex justify-start ml-3 mt-3">
                  <v-col cols="12">
                    <!-- Save Button -->
                    <v-btn class="dialog_save_btn" @click="saveInput">{{
                      save_btn
                    }}</v-btn>
                    <br />
                    <v-icon
                      class="mt-8"
                      color="#00C3C0"
                      @click="infoDialog = true"
                      >mdi mdi-information</v-icon
                    >
                    <p>¿Dónde encontrar el Integration Key?</p>
                  </v-col>
                  <v-btn @click="BacktoDash" class="mr-3 BackToDashBtn"
                    ><v-icon class="iconBtnPG mr-2"
                      >mdi mdi-keyboard-return</v-icon
                    >Volver a MindsetFit</v-btn
                  >
                </v-row>
              </v-form>
            </v-card-text>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="confirmDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Confirmación</v-card-title>
        <v-card-text
          >¿Estás seguro que deseas cambiar tu Integration Key?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="confirmDialog = false"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="confirmChange"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Information Icon and Dialog -->
    <v-dialog v-model="infoDialog" fullscreen persistent>
      <v-card>
        <v-card-title class="headline">
          Información
          <v-spacer></v-spacer>
          <v-btn icon @click="infoDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-carousel>
            <v-carousel-item>
              <v-img
                src="~@/assets/Activation2.png"
                max-width="100%"
                contain
              ></v-img>
            </v-carousel-item>
            <v-carousel-item>
              <v-img
                src="~@/assets/Activation.png"
                max-width="100%"
                contain
              ></v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Success Snackbar -->
    <v-snackbar v-model="snackbar.success" :timeout="5000" color="success" top>
      Integration Key de Team Tailor actualizada con éxito
      <v-btn color="white" text @click="snackbar.success = false">Cerrar</v-btn>
    </v-snackbar>

    <!-- Error Snackbar -->
    <v-snackbar v-model="snackbar.error" :timeout="5000" color="error" top>
      Error a la hora de actualizar la Integration Key de Team Tailor
      <v-btn color="white" text @click="snackbar.error = false">Cerrar</v-btn>
    </v-snackbar>

    <!-- Warning Snackbar -->
    <v-snackbar v-model="snackbar.warning" :timeout="5000" color="warning" top>
      Ya has introducido este Integration Key
      <v-btn color="white" text @click="snackbar.warning = false">Cerrar</v-btn>
    </v-snackbar>
  </v-sheet>
</template>

<script>
import { useDisplay } from "vuetify";
import { mapState, mapMutations, mapActions } from "vuex";
import IntegrationsService from "@/services/IntegrationsService";

export default {
  data: () => ({
    form: false,
    newtag: "",
    title: "",
    newtag_placeholder: "Ingrese su Integration Key de Team Tailor",
    newtagRules: [(v) => !!v || "El campo es requerido"],
    save_btn: "Guardar",
    infoDialog: false, // Estado del diálogo de información
    confirmDialog: false, // Estado del diálogo de confirmación
    snackbar: {
      success: false,
      error: false,
      warning: false,
    },
  }),
  computed: {
    ...mapState(["dashboard_step", "user_info", "integrationKey"]),
  },
  watch: {
    dashboard_step(newVal) {
      if (newVal === 4) {
        this.getIntegrationKey();
      }
    },
    integrationKey(newVal) {
      if (!newVal) {
        this.newtag = "";
      }
    },
  },
  methods: {
    ...mapMutations(["dashboard_step_to_2", "setIntegrationKey"]),
    ...mapActions(["fetchIntegrationKey"]),
    showIntegrationsInput() {
      // Mostrar el input y botones de integraciones
    },
    saveInput() {
      if (this.newtag) {
        if (this.newtag === this.integrationKey) {
          this.snackbar.warning = true;
        } else if (this.integrationKey) {
          this.confirmDialog = true;
        } else {
          this.postIdTT(this.newtag);
        }
      }
    },
    confirmChange() {
      this.confirmDialog = false;
      this.postIdTT(this.newtag);
    },
    BacktoDash() {
      this.dashboard_step_to_2();
    },
    postIdTT(newtag) {
      IntegrationsService.postTeamTailorId(
        this.user_info.tokens.tokenPE,
        newtag
      )
        .then((response) => {
          if (response && response.data && response.data.status === 200) {
            this.snackbar.success = true;
            this.setIntegrationKey(newtag); // Actualiza la Integration Key en el store
          } else {
            this.snackbar.error = true;
          }
        })
        .catch((error) => {
          this.snackbar.error = true;
          console.error("ID no subida", error);
        });
    },
    getIntegrationKey() {
      this.fetchIntegrationKey().then(() => {
        if (!this.integrationKey) {
          this.newtag = "";
        }
      });
    },
  },
  setup() {
    const { mdAndUp } = useDisplay();
    return { mdAndUp };
  },
};
</script>

<style scoped>
.btn {
  background-color: #00c3c0;
  color: white;
  font-size: 12px;
}
.smallerTButton {
  font-size: 10px !important;
}
.newtag_tf {
  background-color: #bdf0ee;
}
.dialog_save_btn {
  background-color: #00c3c0;
  color: white;
}
.BackToDashBtn {
  background-color: #2c3e50 !important;
  color: white !important;
}
</style>
