<template>
  <v-card
    elevation="0"
    :class="
      mdAndUp
        ? 'cardwhitefArea overflow-auto'
        : 'mt-1 text-center cardwhitefArea '
    "
    :height="principles_card_height"
    width="95%"
  >
    <v-row>
      <div class="d-flex flex-column justify-content align-center">
        <h3 class="text-uppercase mt-5 text-center" style="color: #34495e">
          Roles laborales de alto y bajo encaje
        </h3>
        <p class="subtitlep mx-10 d-flex text-center">
          En base a mentalidades más y menos predominantes se identifican roles
          en los que la persona desarrollará, o no, todo su potencial
        </p>
      </div>
      <v-col cols="5" class="d-flex borderblackFAreas ml-10">
        <h5 style="color: #585858" class="descriptionFitA text-uppercase">
          Alto encaje<v-icon class="highEng">mdi-arrow-up</v-icon>
        </h5>
      </v-col>
      <v-col cols="5" class="d-flex borderblackFAreas ml-4">
        <h5 style="color: #585858" class="descriptionFitA text-uppercase">
          Bajo encaje <v-icon class="lowEng">mdi-arrow-down</v-icon>
        </h5>
      </v-col>
    </v-row>

    <v-row :class="mdAndUp ? ' mx-7 ' : 'mx-3 mt-5'" style="height: 100%">
      <v-col class="d-flex flex-column">
        <v-row>
          <v-col cols="5" class="d-flex pa-0">
            <!-- alto encaje -->

            <ul>
              <li
                v-for="(item, index) in fitAreasData.archMax1Area.split(',')"
                :key="index"
                class="descriptionfArea borderblackFAreas2 list"
              >
                {{ item.trim() }}
              </li>

              <li
                v-for="(item, index) in fitAreasData.archMax2Area.split(',')"
                :key="index"
                class="descriptionfArea borderblackFAreas2 list"
              >
                {{ item.trim() }}
              </li>
            </ul>
          </v-col>

          <!--            bajo encaje -->

          <v-col cols="5" class="d-flex pa-0 ml-13">
            <div>
              <ul>
                <li
                  v-for="(item, index) in fitAreasData.archMin1Area.split(',')"
                  :key="index"
                  class="descriptionfArea borderblackFAreas2b list"
                >
                  {{ item.trim() }}
                </li>

                <li
                  v-for="(item, index) in fitAreasData.archMin2Area.split(',')"
                  :key="index"
                  class="descriptionfArea borderblackFAreas2b list"
                >
                  {{ item.trim() }}
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { computed } from "vue";
import { useDisplay } from "vuetify";

export default {
  name: "FitAreasSection",

  data: () => ({}),

  computed: {
    ...mapState(["cultureProfileData", "fitAreasData"]),
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const principles_card_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (80 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (79 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (60 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (83 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return {
      principles_card_height,
      mdAndUp,
      xsAndUp,
    };
  },
};
</script>

<style>
.borderblackFAreas {
  border-bottom: 1px solid #000000;
}
.borderblackFAreas2 {
  border-bottom: 3px solid #b1d095;
  color: #777777;
}
.borderblackFAreas2b {
  border-bottom: 3px solid #f9d786;
  color: #777777;
}
.descriptionfArea {
  color: #797979;
  font-size: 12px;
}
.descriptionFitA {
  color: #585858;
  font-size: 16px;
}

.col2 {
  border-left: 2px solid #00c3c0 !important;
}
.col1 {
  border-right: 2px solid #00c3c0 !important;
}

.principles_card::-webkit-scrollbar {
  -webkit-appearance: none;
}

.principles_card::-webkit-scrollbar:vertical {
  width: 10px;
}

.principles_card::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none;
}

.principles_card::-webkit-scrollbar:horizontal {
  height: 10px;
}

.principles_card::-webkit-scrollbar-thumb {
  background-color: #00c3c0;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.principles_card::-webkit-scrollbar-track {
  border-radius: 10px;
}
.cardwhitefArea {
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #efefef;

  margin-top: 0%;
  margin-left: 1%;
}
.list {
  list-style-type: none;
  margin: 18px 0px 15px 0px;
}
.highEng {
  color: #b1d095;
  font-size: 25px;
}
.lowEng {
  color: #f9d786;
  font-size: 25px;
}
.subtitlep {
  color: #585858;

  font-size: 13px;
}
</style>
