<template>
  <v-sheet color="transparent" width="100%">
    <!--Received Answers Info-->
    <v-row dense class="d-flex justify-center">
      <v-col cols="10" md="10">
        <div class="rounded h-100 w-100 py-4" style="background-color: #00c3c0">
          <v-row dense>
            <v-col cols="8" class="d-flex align-center">
              <div class="w-25 ml-5">
                <h3 style="color: #34495e">{{ received_answers }}</h3>
              </div>
            </v-col>
            <v-col cols="4" class="d-flex align-center justify-end">
              <h1 class="mr-5" style="color: #34495e">{{ summary }}</h1>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <!--    <v-row dense class="mt-5 d-flex justify-center">
      <v-col cols="10" md="10">
        <P class="font-weight-bold" style="color: #585858; font-size: 14px">
          {{ static_text }}
        </P>
      </v-col>
    </v-row> -->

    <!--Share Link Button-->
    <v-row dense class="mt-3 d-flex justify-center">
      <v-col cols="10" md="10">
        <v-btn
          block
          rounded="lg"
          density="comfortable"
          size="large"
          class="font-weight-bold btn py-7 smallerTButtonPlus"
          @click="write_to_clipboard"
        >
          {{ link_btn1 }} <br />
          {{ link_btn2 }}
          <v-tooltip activator="parent" location="end">{{
            tooltip_link
          }}</v-tooltip>
          <v-dialog
            v-model="dialog"
            :width="mdAndUp ? '50%' : '90%'"
            transition="dialog-top-transition"
          >
            <v-card
              class="d-flex justify-center text-center"
              color="#00C3C0"
              theme="dark"
            >
              <v-container>
                <!-- Dialog Title-->
                <h2 class="text-uppercase" style="color: white">
                  <v-icon>mdi mdi-check-circle-outline</v-icon>
                  {{ dialog_title }}
                </h2>
                <!--Dialog Subtitle 1-->
                <v-row dense class="d-flex justify-center text-center">
                  <v-col cols="11" md="10">
                    <div>
                      <p class="text-subtitle-1 mt-3" style="color: white">
                        {{ dialog_subtitle }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <v-card-text>
                  <a
                    :href="internal_link"
                    target="_blank"
                    class="mt-5"
                    style="color: white"
                    >{{ internal_link }}
                  </a>
                </v-card-text>
              </v-container>
            </v-card>
          </v-dialog>
        </v-btn>
      </v-col>
    </v-row>

    <!--Get Company Report Button-->
    <v-row dense class="mt-3 d-flex justify-center hidden-sm-and-down">
      <v-col cols="10" md="10">
        <v-btn
          block
          rounded="lg"
          density="comfortable"
          size="large"
          class="font-weight-bold btn py-7 hidden-sm-and-down smallerTButton"
          :href="report_link"
          target="_blank"
        >
          {{ report_btn }}
          <v-tooltip activator="parent" location="end">{{
            tooltip_report
          }}</v-tooltip>
        </v-btn>
      </v-col>
    </v-row>

    <!--Contact Button-->
    <v-row dense class="mt-3 d-flex justify-center hidden-sm-and-down">
      <v-col cols="10" md="10">
        <v-btn
          block
          rounded="lg"
          density="comfortable"
          size="large"
          class="font-weight-bold btn py-7 hidden-sm-and-down smallerTButton"
          :href="contact_link"
          target="_blank"
        >
          {{ contact_btn }}
        </v-btn>
      </v-col>
    </v-row>

    <!--Support Contact Button-->
    <v-row dense class="mt-3 d-flex justify-center hidden-sm-and-down">
      <v-col cols="10" md="10">
        <v-btn
          block
          rounded="lg"
          density="comfortable"
          size="large"
          class="font-weight-bold btn py-7 hidden-sm-and-down smallerTButton"
          href="https://web.culturalfitsolutions.com/ticketing-plataforma-cultural-fit"
          target="_blank"
        >
          {{ support_contact_btn }}
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import { useDisplay } from "vuetify";

import Links from "@/globals/Links";

export default {
  name: "DashboardButtonsSection",

  data: () => ({
    //alert
    alert: false,

    dialog: false,

    snackbar: true,

    //Label
    received_answers: "",
    static_text: "",
    link_btn1: "",
    link_btn2: "",
    report_btn: "",
    contact_btn: "",
    support_contact_btn: "",
    support_contact_link: "",
    contact_link: "",
    report_link: "",
    tooltip_link: "",
    tooltip_report: "",

    dialog_title: "",
    dialog_subtitle: "",
  }),

  computed: {
    ...mapState(["summary", "internal_link"]),
  },

  methods: {
    async write_to_clipboard() {
      try {
        await navigator.clipboard.writeText(this.internal_link);
      } catch (err) {
        console.error("Error al copiar al portapapeles:", err);
      }
      this.dialog = true;
    },

    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);
      this.received_answers = texts[40];
      this.static_text = texts[41] + ":";
      this.link_btn1 = texts[42];
      this.link_btn2 = texts[43];
      this.report_btn = texts[44];
      this.contact_btn = texts[45];
      this.support_contact_btn = texts[46];
      this.contact_btn = texts[45];
      // this.support_contact_btn = texts[46];

      this.tooltip_link = texts[60];
      this.tooltip_report = texts[61];

      this.dialog_title = texts[63];
      this.dialog_subtitle = texts[64] + ":";

      this.tooltip_link = texts[60];
      this.tooltip_report = texts[61];

      this.contact_link = Links.contact_link;
      this.support_contact_link = Links.support_contact_link;
      this.report_link = Links.report_link;
    },
  },

  created() {
    this.set_texts();
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();

    return { mdAndUp };
  },
};
</script>

<style scoped>
.btn {
  background-color: #34495e;
  color: white;
  font-size: 12px;
}
</style>
