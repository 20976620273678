<template>
  <v-form v-model="form" aria-autocomplete="false">
    <!--User Information Section-->
    <v-row class="pa-0">
      <v-col cols="4" md="3" class="pa-0">
        <p class="sections_title">{{ section1_title }}</p>
      </v-col>
      <v-col cols="8" md="9" class="pa-0">
        <span class="custom-line mt-3"></span>
      </v-col>
    </v-row>

    <v-row class="pa-0">
      <!--Full Name-->
      <v-col cols="12" class="pa-0">
        <v-text-field
          v-model="current_user.full_name"
          required
          class="text-fields mt-2"
          density="compact"
          type="text"
          bg-color="#BDF0EE"
          :label="full_name_label"
          variant="flat"
          single-line
          hide-details
          :rules="fieldsRules"
          @keydown.enter="save_changes"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0" class="mr-2">mdi mdi-account</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <!--Company Name-->
      <v-col cols="12" class="pa-0">
        <v-text-field
          v-model="current_user.company_name"
          required
          class="text-fields mt-3"
          density="compact"
          type="text"
          bg-color="#BDF0EE"
          :label="company_name_label"
          variant="flat"
          single-line
          hide-details
          :rules="fieldsRules"
          @keydown.enter="save_changes"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0" class="mr-2">mdi mdi-domain</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <!--Position-->
      <v-col cols="12" class="pa-0">
        <v-text-field
          v-model="current_user.position"
          required
          class="text-fields mt-3"
          density="compact"
          type="text"
          bg-color="#BDF0EE"
          :label="position_label"
          variant="flat"
          single-line
          hide-details
          autocomplete="off"
          @keydown.enter="save_changes"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0" class="mr-2">mdi mdi-account-tie</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <!--Email-->
      <v-col cols="12" class="pa-0">
        <v-text-field
          v-model="current_user.email"
          required
          class="text-fields mt-3"
          density="compact"
          type="email"
          bg-color="#BDF0EE"
          :label="email_label"
          variant="flat"
          single-line
          hide-details
          disabled
          :rules="fieldsRules"
          @keydown.enter="save_changes"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0" class="mr-2">mdi mdi-email</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <!--Phone-->
      <v-col cols="12" class="pa-0">
        <v-text-field
          v-model="current_user.phone"
          required
          class="text-fields mt-3"
          density="compact"
          type="text"
          bg-color="#BDF0EE"
          :label="phone_label"
          variant="flat"
          single-line
          hide-details
          autocomplete="new-phone"
          @keydown.enter="save_changes"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0" class="mr-2">mdi mdi-phone</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>

    <!--Password Section-->
    <v-row class="pa-0 mt-7">
      <v-col cols="3" md="2" class="pa-0">
        <p class="sections_title">{{ section2_title }}</p>
      </v-col>
      <v-col cols="9" md="10" class="pa-0">
        <span class="custom-line mt-3"></span>
      </v-col>
    </v-row>

    <v-row class="pa-0">
      <!--New Password-->
      <v-col cols="12" class="pa-0">
        <v-text-field
          v-model="new_password"
          class="text-fields mt-2"
          density="compact"
          :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          @click:append-inner="show1 = !show1"
          bg-color="#BDF0EE"
          :label="new_password_label"
          variant="flat"
          single-line
          hide-details
          autocomplete="new-password"
          @keydown.enter="save_changes"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0" class="mr-2">mdi mdi-lock</v-icon>
          </template>
        </v-text-field>
      </v-col>

      <!--New Password Repeat-->
      <v-col cols="12" class="pa-0">
        <v-text-field
          v-model="new_password_repeat"
          class="text-fields mt-2"
          density="compact"
          :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show2 ? 'text' : 'password'"
          @click:append-inner="show2 = !show2"
          bg-color="#BDF0EE"
          :label="new_password_repeat_label"
          variant="flat"
          single-line
          hide-details
          autocomplete="off"
          @keydown.enter="save_changes"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0" class="mr-2">mdi mdi-lock</v-icon>
          </template>
        </v-text-field>
        <div class="text-center">
          <p class="mt-2" style="color: #585858; font-size: 10px">
            {{ password_requirements }}
          </p>
        </div>
      </v-col>
    </v-row>

    <!--Save Button-->
    <v-row class="pa-0">
      <v-col cols="12" class="pa-0">
        <v-btn
          block
          rounded="lg"
          density="comfortable"
          size="large"
          :class="
            mdAndUp
              ? 'font-weight-bold save_button mt-5'
              : 'font-weight-bold save_button mt-10'
          "
          @click="save_changes"
        >
          {{ save_btn }}
        </v-btn>
        <v-btn
          block
          rounded="lg"
          density="comfortable"
          color="error"
          size="large"
          :class="
            mdAndUp ? 'font-weight-bold mt-3' : 'font-weight-bold mt-4 mb-15'
          "
          @click="cancel_changes"
        >
          {{ back_btn }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>

  <v-dialog v-model="dialog" width="auto">
    <v-card class="d-flex justify-center text-center">
      <v-container>
        <!--Title Dialog-->
        <v-card-title class="mt-3" style="color: #00c3c0">
          {{ title }}
        </v-card-title>
        <v-card-subtitle class="" style="color: #34495e">
          {{ subtitle }}
        </v-card-subtitle>

        <v-card-text>
          <!--Buttons Dialog-->
          <v-row dense class="mt-8">
            <v-col cols="12">
              <!--Dialog Cancel Button -->
              <v-btn @click="dialog = false" color="error" class="mr-3">
                {{ cancel_btn }}
              </v-btn>
              <!--Dialog Save Button -->
              <v-btn class="save_btn" @click="save_all_changes">
                {{ save_btn }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
  </v-dialog>

  <v-snackbar
    v-model="snackbar"
    :timeout="2000"
    variant="elevated"
    class="mb-5"
    location="bottom right"
    color="error"
    width="auto"
    transition="scroll-x-reverse-transition"
  >
    <h3 class="text-white">
      <v-icon color="white" class="mr-1" size="30">mdi mdi-check-circle</v-icon>
      {{ snack_message }}
    </h3>
  </v-snackbar>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { ROUTES } from "/src/router/names";
import { useDisplay } from "vuetify";
import { computed } from "vue";

import UserService from "@/services/UserService";

export default {
  name: "ProfileForm",
  components: {},

  data: () => ({
    //Show
    show1: false,
    show2: false,

    dialog: false,

    //Snackbar Notification
    snackbar: false,
    snack_message: "",

    //Sections Title
    section1_title: "",
    section2_title: "",

    //Save Button
    save_btn: "",
    cancel_btn: "",
    back_btn: "",

    current_user: {},

    new_password: "",
    new_password_repeat: "",

    //Text Fields Rules
    fieldsRules: [(v) => !!v || "Usuario requerido"],

    //Text Field labels
    full_name_label: "",
    email_label: "",
    company_name_label: "",
    position_label: "",
    phone_label: "",
    password_label: "",
    new_password_label: "",
    new_password_repeat_label: "",

    password_requirements: "",

    //Titles
    title: "",
    subtitle: "",

    password: 0,

    ROUTES: ROUTES,
  }),

  computed: {
    ...mapState(["user_info"]),
  },

  created() {
    this.clean_fields();
    this.cloneUser();
    this.set_texts();
  },

  methods: {
    ...mapMutations(["dashboard_step_to_2", "setUser"]),

    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);

      this.section1_title = texts[28];
      this.section2_title = texts[9];
      this.save_btn = texts[32];
      this.cancel_btn = texts[36];
      this.back_btn = texts[33];
      this.full_name_label = texts[6];
      this.email_label = texts[7];
      this.company_name_label = texts[8];
      this.position_label = texts[29];
      this.phone_label = texts[30];
      this.new_password_label = texts[31];
      this.new_password_repeat_label = texts[10];
      this.password_requirements = texts[11];
      this.title = texts[34];
      this.subtitle = texts[35];
    },

    clean_fields() {
      this.new_password = "";
      this.new_password_repeat = "";
      this.dialog = false;
    },

    cloneUser() {
      this.current_user = structuredClone(this.user_info);
    },

    validPassword(password) {
      var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@$!%*?&_.]{6,20}/;
      return re.test(password);
    },

    validPhone(phone) {
      var re = /^[+]?[" "\d]{7,20}$/;
      return re.test(phone);
    },

    save_changes() {
      if (
        this.current_user.phone == "" ||
        this.validPhone(this.current_user.phone)
      ) {
        if (this.new_password != "" || this.new_password_repeat != "") {
          if (this.new_password == this.new_password_repeat) {
            if (this.validPassword(this.new_password)) {
              this.dialog = true;
              this.password = 1;
            } else {
              this.snack_message =
                "Contraseña muy débil. Debe cumplir con los requisitos";
              this.snackbar = true;
              this.new_password = "";
              this.new_password_repeat = "";
            }
          } else {
            this.snack_message =
              "Las contraseñas no coinciden. Vuelva a intentarlo.";
            this.snackbar = true;
            this.new_password = "";
            this.new_password_repeat = "";
          }
        } else {
          this.dialog = true;
          this.password = 0;
        }
      } else {
        this.snack_message = "Formato de teléfono no válido.";
        this.snackbar = true;
        this.user_info.phone = "";
      }
    },

    cancel_changes() {
      this.dashboard_step_to_2();
      this.$router.replace({ name: ROUTES.DASHBOARD });
    },

    save_all_changes() {
      if (this.password == 1) {
        this.updateUserWithPassword();
      } else {
        this.updateUserWithoutPassword();
      }
    },

    async updateUserWithPassword() {
      let formData = new FormData();
      formData.append("full_name", this.current_user.full_name);
      formData.append("company_name", this.current_user.company_name);
      formData.append("email", this.current_user.email);
      formData.append("password", this.new_password);
      formData.append("position", this.current_user.position);
      formData.append("phone", this.current_user.phone);

      try {
        const response = await UserService.updateUser(formData);
        if (response.status == 200) {
          this.setUser(this.current_user);
          this.dashboard_step_to_2();
          this.$router.replace({ name: ROUTES.DASHBOARD });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async updateUserWithoutPassword() {
      let formData = new FormData();
      formData.append("full_name", this.current_user.full_name);
      formData.append("company_name", this.current_user.company_name);
      formData.append("email", this.current_user.email);
      formData.append("position", this.current_user.position);
      formData.append("phone", this.current_user.phone);

      try {
        const response = await UserService.updateUser(formData);

        if (response.status == 200) {
          this.setUser(this.current_user);
          this.dashboard_step_to_2();
          this.$router.replace({ name: ROUTES.DASHBOARD });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { smAndDown } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const card_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (92 * ((88 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (90 * ((88 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (90 * ((88 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (90 * ((88 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    const card_width = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "90%";
        case "sm":
          return "90%";
        case "md":
          return (85 * window.innerWidth) / 100;
        case "lg":
          return (75 * window.innerWidth) / 100;
        case "xl":
          return (75 * window.innerWidth) / 100;
        case "xxl":
          return (75 * window.innerWidth) / 100;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return { card_height, card_width, mdAndUp, xsAndUp, smAndDown };
  },
};
</script>

<style scoped>
.text-fields {
  font-style: italic;
  font-weight: bold;
}

.save_button {
  background-color: #34495e;
  color: white;
}

.text-fields :-webkit-autofill,
.text-fields :-webkit-autofill:hover,
.text-fields :-webkit-autofill:focus,
.text-fields :-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #585858 !important;
}

.text-fields .v-text-field--outlined >>> fieldset {
  border-color: rgba(192, 0, 250, 0.986);
}

.sections_title {
  color: #34495e;
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
}

.custom-line {
  border: 1px solid #00c3c0;
  display: block;
}

.save_btn {
  background-color: #34495e;
  color: white;
}
</style>
