import { createRouter, createWebHistory } from "vue-router";

//Layouts
import LoginLayout from "../layouts/LoginLayout.vue";
import DashboardLayout from "../layouts/DashboardLayout.vue";
import EditProfileLayout from "../layouts/EditProfileLayout.vue";
import RecoverPasswordLayout from "../layouts/RecoverPasswordLayout.vue";

//Views
import LoginView from "../views/LoginView.vue";
import TestInfoView from "../views/TestInfoView.vue";
import DashboardView from "../views/DashboardView.vue";
import EditProfileView from "../views/EditProfileView.vue";
import RecoverPasswordView from "../views/RecoverPasswordView.vue";
import BoxParameterizationView from "../views/BoxParameterizationView.vue";

import { ROUTES } from "./names.js";

const routes = [
  {
    path: "/",
    name: ROUTES.LOGIN,
    component: LoginLayout,
    children: [
      {
        path: "",
        name: "login_view",
        component: LoginView,
      },
    ],
  },
  {
    path: "/testinfo",
    name: ROUTES.TESTINFO,
    component: TestInfoView,
  },
  {
    path: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: ROUTES.DASHBOARD,
        component: DashboardView,
      },
      {
        path: "box-parameterization",
        name: ROUTES.BOX_PARAMETERIZATION,
        component: BoxParameterizationView,
        props: true,
      },
    ],
  },
  {
    path: "/profile",
    name: ROUTES.EDIT_USER,
    component: EditProfileLayout,
    children: [
      {
        path: "",
        name: "profile_view",
        component: EditProfileView,
      },
    ],
  },
  {
    path: "/recover_password",
    name: ROUTES.RECOVER_PASSWORD,
    component: RecoverPasswordLayout,
    children: [
      {
        path: "",
        name: "recover_password_view",
        component: RecoverPasswordView,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
