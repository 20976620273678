export default {
  spanish: [
    "Orientación a resultados",
    "Formalidad",
    "Excelencia",
    "Coordinación",
    "Enfoque analítico",
    "Estabilidad",
    "Ambición",
    "Pragmatismo",
    "Responsabilidad",
    "Perseverancia",
    "Competitividad",
    "Eficiencia",
  ],

  english: [],
};
