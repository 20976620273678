<template>
  <v-container fluid>
    <v-row>
      <v-btn class="ml-9" icon @click="infoDialog = true">
        <v-icon class="icon-info">mdi-information</v-icon>
      </v-btn>
      <span class="ml-3">--- instrucciones</span>
      <v-text-field
        class="ml-9"
        v-model="IdealName"
        label="Nombre de la mentalidad ideal"
        type="text"
        :error="nameError"
        :error-messages="nameErrorMessage"
        required
      ></v-text-field>
    </v-row>

    <v-row>
      <v-col cols="12" lg="12">
        <v-row>
          <v-col
            cols="12"
            md="2"
            v-for="(block, index) in orderedBlocks"
            :key="`block-${index}`"
          >
            <v-card>
              <v-card-text
                class="headerTop"
                :style="{ backgroundColor: colorScale[index] }"
              >
                {{ block.header }}
              </v-card-text>

              <div
                class="drag-area"
                :class="{
                  'dark-hover': recentlySwapped.includes(
                    `item-${idx}-${block.header}`
                  ),
                }"
                v-for="(item, idx) in block.items"
                :key="`item-${idx}-${index}`"
                :draggable="true"
                @dragstart="handleDragStart($event, item, block, idx)"
                @dragover.prevent="handleDragOver"
                @drop="handleDrop(item, idx, block)"
                @dragend="handleDragEnd"
              >
                <div>
                  {{ Object.keys(item)[0] }}
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" lg="1" class="mt-5">
        <v-btn class="btnParaG" @click="go_back">
          <v-icon class="iconBtnPG">mdi mdi-keyboard-return</v-icon>
          Volver <br />a panel
        </v-btn>
      </v-col>
      <v-col cols="12" lg="4">
        <div class="text-center" style="height: 400px; width: 400px">
          <h3>Gráfica de mentalidad ideal</h3>
          <apexchart
            type="radar"
            :height="chart_height"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </v-col>

      <v-col cols="12" lg="4">
        <div class="table-container mt-2">
          <h3>Predominancia de Mentalidades</h3>
          <div
            class="table-row"
            v-for="(item, index) in sortedTotalScores2"
            :key="`score-row-${index}`"
          >
            <!--  <div class="circle-cell">
              <v-icon
                :color="colorScale[index % colorScale.length]"
                size="small"
              >
                mdi-circle
              </v-icon>
            </div> -->
            <div class="title-cell">
              {{ item.category.replace("Total", "") }}
            </div>
            <div class="number-cell">Top {{ index + 1 }}</div>
          </div>
        </div>
      </v-col>

      <v-col cols="12" lg="2">
        <v-row>
          <v-btn class="mt-8 mr-2 mx-auto btn" @click="checkBeforePosting">
            Grabar
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="confirmDialogVisible" persistent max-width="500px">
      <v-card>
        <v-card-title>Confirmación</v-card-title>
        <v-card-text>
          ¿Estás seguro de que quieres has terminado esta parametrización de
          mentalidad ideal en base a valores?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirmParametrization"
            >Confirmar</v-btn
          >
          <v-btn color="red darken-1" text @click="cancelParametrization"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="infoDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline">Instrucciones</v-card-title>
        <v-card-text>
          <v-row justify="center drag-item3 ">
            <v-col cols="12" lg="12">
              <p>
                <strong>Paso 2:</strong> Este es el orden de valores que hemos
                establecido en función de tus respuestas en el paso 1.<br /><br />
              </p>

              <div
                v-for="text in texts"
                :key="`info-${text.id}`"
                class="step1Text"
              >
                <v-text v-html="text.message"> </v-text>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="infoDialog = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row dense class="mt-3 d-flex justify-center">
      <v-col cols="10" md="10">
        <v-dialog
          v-model="dialogVisible"
          :width="mdAndUp ? '50%' : '70%'"
          transition="dialog-top-transition"
        >
          <v-card
            class="d-flex justify-center text-center"
            color="#00C3C0"
            theme="dark"
          >
            <v-btn
              icon
              class="close-dialog"
              @click="dialogVisible = false"
              style="
                position: absolute;
                top: 2px;
                right: 2px;
                background-color: #506274;
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-container>
              <h2 class="text-uppercase" style="color: white">
                <v-icon>mdi mdi-check-circle-outline</v-icon> Enhorabuena
              </h2>
              <v-row dense class="d-flex justify-center text-center">
                <v-col cols="11" md="10">
                  <div>
                    <p class="text-subtitle-1 mt-3" style="color: white">
                      Ya has parametrizado la mentalidad ideal en base a
                      valores.<br />
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row dense class="d-flex justify-center text-center">
                <v-col cols="11" md="10">
                  <div>
                    <p class="text-subtitle-1 mt-3" style="color: white">
                      Dirígete a la tabla de perfiles y asígnale esta
                      parametrización a una persona en la columna<strong>
                        "comparar con"</strong
                      >
                      <br />
                      Una vez lo hayas hecho, podrás hace click en el nombre de
                      la persona y tendrás los resultados en la pestaña
                      <br />
                      <strong>'Mindset Fit'</strong>
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useDisplay } from "vuetify";
import { computed } from "vue";
import { mapMutations } from "vuex";
import { mapState } from "vuex";
import ProfileEvaluationService from "@/services/ProfileEvaluationService";

export default {
  data() {
    return {
      infoDialog: false,
      texts: [
        {
          id: 1,
          message:
            "Si lo deseas, puedes intercambiar valores entre bloques, <strong>Haz click, Arrastra y Suelta</strong> sobre los valores que quieres intercambiar.<br/><br/>",
        },
        {
          id: 2,
          message:
            "El intercambio de valores podrá influir en la priorización de mentalidades establecida en el paso 1. <br/><br/>",
        },
        {
          id: 3,
          message:
            "Cuando hayas terminado,  dale un nombre a tu parametrización y pulsa en el botón <strong>'Grabar'</strong>.",
        },
      ],
      IdealName: "",
      nameError: false,
      nameErrorMessage: "",
      dialogVisible: false,
      confirmDialogVisible: false,
      itemScores: [],
      totalScores: {
        TotalPeople: 0,
        TotalInnovation: 0,
        TotalGoals: 0,
        TotalNorms: 0,
        TotalDigital: 0,
        TotalESG: 0,
      },
      summatoryValues: [0, 0, 0, 0, 0, 0],
      series: [
        {
          name: "Predominance Values",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "radar",
          toolbar: { show: false },
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            style: {
              colors: [
                "#34495e",
                "#34495e",
                "#34495e",
                "#34495e",
                "#34495e",
                "#34495e",
              ],
              fontSize: "11px",
              fontFamily: "Arial",
            },
          },
        },
        legend: {
          show: "true",
          position: "top",
        },
        yaxis: { show: false },
        stroke: {
          width: 1,
          colors: ["#34495e"],
        },
        markers: { size: 0 },
        fill: { colors: ["#34495e"] },
      },
      colorScale: [
        "#B1D095",
        "#CADFB8",
        "#E7F7F6",
        "#FFFC8B",
        "#F9D786",
        "#EF857D",
      ],
      draggingItem: null,
      draggingIndex: -1,
      draggingBlock: null,
      targetIndex: -1,
      targetBlock: null,
      recentlySwapped: [],
      blocks: [
        {
          header: "People",
          items: [
            { Cercanía: 1 },
            { Generosidad: 7 },
            { "Trabajo en equipo": 13 },
            { Lealtad: 19 },
            { Confianza: 25 },
            { Compromiso: 31 },
          ],
        },
        {
          header: "Innovation",
          items: [
            { Dinamismo: 2 },
            { Creatividad: 8 },
            { Autonomía: 14 },
            { Innovación: 20 },
            { "Tolerancia al riesgo": 26 },
            { Atrevimiento: 32 },
          ],
        },
        {
          header: "Goals",
          items: [
            { "Orientación a resultados": 3 },
            { Excelencia: 9 },
            { Competitividad: 15 },
            { Pragmatismo: 21 },
            { Ambición: 27 },
            { Liderazgo: 33 },
          ],
        },
        {
          header: "Norms",
          items: [
            { Formalidad: 4 },
            { Coordinación: 10 },
            { Estabilidad: 16 },
            { Responsabilidad: 22 },
            { Perseverancia: 28 },
            { Eficiencia: 34 },
          ],
        },
        {
          header: "Digital",
          items: [
            { "Apertura al cambio": 5 },
            { "Enfoque analítico": 11 },
            { Cooperación: 17 },
            { "Tolerancia al fallo": 23 },
            { "Orientación al aprendizaje": 29 },
            { Empatía: 35 },
          ],
        },
        {
          header: "ESG",
          items: [
            { Bienestar: 6 },
            { Integridad: 12 },
            { Transparencia: 18 },
            { Respeto: 24 },
            { Diversidad: 30 },
            { Sostenibilidad: 36 },
          ],
        },
      ],
      initialBlocks: [], // Nueva propiedad para almacenar el estado inicial de blocks
    };
  },

  computed: {
    ...mapState(["user_info", "orderedHeaders"]),
    detailedScores() {
      let itemsWithDetails = [];
      this.orderedBlocks.forEach((block, blockIndex) => {
        block.items.forEach((item) => {
          const name = Object.keys(item)[0];
          const originalIndex = item[name];
          const score = 6 - blockIndex;
          itemsWithDetails.push({
            name,
            originalIndex,
            score,
          });
        });
      });
      itemsWithDetails.sort((a, b) => a.originalIndex - b.originalIndex);
      return itemsWithDetails;
    },
    sortedTotalScores2() {
      let scoresArray = Object.entries(this.totalScores).map(
        ([key, score]) => ({
          category: key,
          score: score,
        })
      );
      scoresArray.sort((a, b) => b.score - a.score);
      return scoresArray;
    },
    orderedBlocks() {
      return this.orderedHeaders
        ? this.calculateOrderedBlocks(this.orderedHeaders)
        : this.blocks;
    },
    sortedTotalScores() {
      let scoresArray = Object.keys(this.totalScores).map((key) => {
        return { category: key, score: this.totalScores[key] };
      });
      scoresArray.sort((a, b) => b.score - a.score);
      return scoresArray;
    },
  },

  methods: {
    ...mapMutations([
      "parametrization_view_change_step",
      "parametrization_view_change_step_to_desktop",
      "detailed_view_change_step",
      "setCurrentStep",
      "detailed_view_step",
    ]),
    go_back() {
      this.setCurrentStep(4); // Cambia la vista
      this.resetBlocks();
    },

    checkBeforePosting() {
      if (this.IdealName.trim() === "") {
        this.nameError = true;
        this.nameErrorMessage =
          "Por favor, ingresa un nombre para la mentalidad ideal.";
      } else {
        this.nameError = false;
        this.nameErrorMessage = "";
        this.confirmDialogVisible = true;
      }
    },
    confirmParametrization() {
      this.postScores();
      this.confirmDialogVisible = false;
    },
    cancelParametrization() {
      this.confirmDialogVisible = false;
    },
    compileScores() {
      let allScoresConcatenated = "";
      this.detailedScores.forEach((item) => {
        allScoresConcatenated += item.score.toString();
      });
      return allScoresConcatenated;
    },
    async postScores() {
      const scoresToPost = this.compileScores();

      try {
        const response =
          await ProfileEvaluationService.postParametrizeCandidate(
            this.IdealName,
            scoresToPost,
            this.user_info.tokens.tokenPE
          );

        if (response && response.data && response.data.code === 200) {
          this.dialogVisible = true;
          this.resetBlocks();
          this.parametrization_view_change_step_to_desktop();
        }
      } catch (error) {
        console.error(error);
      }
    },
    goToBox() {
      this.parametrization_view_change_step_to_desktop();
    },
    resetScores() {
      this.itemScores = [];
      this.summatoryValues = [0, 0, 0, 0, 0, 0];
      this.totalScores = {
        TotalPeople: 0,
        TotalInnovation: 0,
        TotalGoals: 0,
        TotalNorms: 0,
        TotalDigital: 0,
        TotalESG: 0,
      };
    },
    calculateOrderedBlocks(order) {
      return order
        .map((header, index) => ({
          ...this.blocks.find((block) => block.header === header),
          header: `Top ${index + 1}`,
        }))
        .filter((block) => block);
    },
    handleDragStart(event, item, block, itemIndex) {
      this.draggingItem = item;
      this.draggingIndex = itemIndex;
      this.draggingBlock = block;
      event.dataTransfer.effectAllowed = "move";
    },
    handleDragOver(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
    },
    handleDrop(targetItem, targetIndex, targetBlock) {
      if (!this.draggingItem) return;

      const draggingItem = this.draggingItem;
      const originalBlock = this.draggingBlock;
      const originalIndex = this.draggingIndex;

      targetBlock.items.splice(targetIndex, 1, draggingItem);
      originalBlock.items.splice(originalIndex, 1, targetItem);

      this.recentlySwapped.push(`item-${targetIndex}-${targetBlock.header}`);
      this.recentlySwapped.push(
        `item-${originalIndex}-${originalBlock.header}`
      );

      setTimeout(() => {
        this.recentlySwapped = this.recentlySwapped.filter(
          (item) =>
            item !== `item-${targetIndex}-${targetBlock.header}` &&
            item !== `item-${originalIndex}-${originalBlock.header}`
        );
      }, 1500);

      this.calculateScores();
      this.draggingItem = null;
      this.draggingIndex = -1;
      this.draggingBlock = null;
      this.targetIndex = -1;
      this.targetBlock = null;
    },
    handleDragEnd() {
      this.draggingItem = null;
      this.draggingIndex = -1;
      this.draggingBlock = null;
    },
    set_texts() {
      let a = sessionStorage.getItem("archetypes");
      this.chartOptions.xaxis.categories = JSON.parse(a);
    },
    calculateScores() {
      let newScores = [];
      let newSummatoryValues = [0, 0, 0, 0, 0, 0];

      const itemGroups = {
        People: [
          "Cercanía",
          "Generosidad",
          "Trabajo en equipo",
          "Lealtad",
          "Confianza",
          "Compromiso",
        ],
        Innovation: [
          "Dinamismo",
          "Creatividad",
          "Autonomía",
          "Innovación",
          "Tolerancia al riesgo",
          "Atrevimiento",
        ],
        Goals: [
          "Orientación a resultados",
          "Excelencia",
          "Competitividad",
          "Pragmatismo",
          "Ambición",
          "Liderazgo",
        ],
        Norms: [
          "Formalidad",
          "Coordinación",
          "Estabilidad",
          "Responsabilidad",
          "Perseverancia",
          "Eficiencia",
        ],
        Digital: [
          "Apertura al cambio",
          "Enfoque analítico",
          "Cooperación",
          "Tolerancia al fallo",
          "Orientación al aprendizaje",
          "Empatía",
        ],
        ESG: [
          "Bienestar",
          "Integridad",
          "Transparencia",
          "Respeto",
          "Diversidad",
          "Sostenibilidad",
        ],
      };

      const order = [
        "People",
        "Innovation",
        "Goals",
        "Norms",
        "Digital",
        "ESG",
      ];

      this.orderedBlocks.forEach((block, index) => {
        const blockScore = 6 - index;
        block.items.forEach((item) => {
          const itemName = Object.keys(item)[0];
          const itemScore = {
            name: itemName,
            score: blockScore,
          };
          newScores.push(itemScore);

          order.forEach((key, idx) => {
            if (itemGroups[key].includes(itemName)) {
              newSummatoryValues[idx] += blockScore;
            }
          });
        });
      });

      this.itemScores = newScores;
      this.summatoryValues = newSummatoryValues;
      this.series[0].data = newSummatoryValues;

      this.totalScores.TotalPeople = newSummatoryValues[0];
      this.totalScores.TotalInnovation = newSummatoryValues[1];
      this.totalScores.TotalGoals = newSummatoryValues[2];
      this.totalScores.TotalNorms = newSummatoryValues[3];
      this.totalScores.TotalDigital = newSummatoryValues[4];
      this.totalScores.TotalESG = newSummatoryValues[5];
    },
    resetBlocks() {
      this.blocks = JSON.parse(JSON.stringify(this.initialBlocks));
    },
  },
  watch: {
    orderedHeaders(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.calculateScores();
      }
    },
  },
  created() {
    this.initialBlocks = JSON.parse(JSON.stringify(this.blocks)); // Almacena el estado inicial de blocks
    this.set_texts();
    this.calculateScores();
  },
  setup() {
    const { name } = useDisplay();
    const chart_height = computed(() => {
      switch (name.value) {
        case "xs":
          return "200%";
        case "sm":
          return "200%";
        case "md":
          return (90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (100 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (100 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }
      return undefined;
    });
    return { chart_height };
  },
};
</script>

<style>
.drag-area {
  min-height: 1.025rem;
  width: 100%;
  background-color: #ffffff;
  cursor: grab;
  border-top: 1px solid #f0f0f0;
  padding: 0.345rem;
  font-size: 1rem;
}
@media (max-width: 1468px) {
  .drag-area {
    font-size: 0.775rem;
  }
}

@media (max-width: 768px) {
  .drag-area {
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .drag-area {
    font-size: 0.75rem;
  }
}

@media (max-width: 320px) {
  .drag-area {
    font-size: 0.65rem;
  }
}

.v-col {
  border: 1px dashed transparent;
}

.v-col.drag-over {
  border-color: #00c3c0;
}

.v-container {
  width: 100%;
  text-align: center;
}

.headerTop {
  width: 100%;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  font-size: 0.5rem;
}

.flex-item {
  flex: 1 1 calc(100% / 6 - 10px);
  min-width: 9.375rem;
  max-width: calc(100% / 6 - 10px);
  font-size: 0.625rem;
}

.circle-cell,
.number-cell {
  text-align: center;
}

.title-cell {
  flex-grow: 1;
  text-align: left;
}

.v-icon.small {
  font-size: 1rem;
}

.dark-hover {
  transition: background-color 0.3s ease;
  background-color: #555 !important ;
  color: white;
}
.step2Text {
  font-size: 14px;
  justify-text: center;
}
.borderInstruc {
  border-bottom: #00c3c0 3px solid;
  border-radius: 8px;
  margin-left: 9px;
}
.close-dialog {
  position: absolute;
  top: 2px; /* 2px desde la parte superior */
  right: 2px; /* 2px desde la derecha */
  z-index: 10; /* Asegurarse de que esté por encima de otros elementos */
}

/* Asegura que el color y el tamaño del ícono se ajusten al diseño del diálogo */
.close-dialog .v-icon {
  color: white;
}
.btnPara {
  background-color: #00c3c0;
  color: white !important;
  font-size: 11px;
  padding: 10px;
  justify-content: center;
}
.btnParaG {
  background-color: #34495e;
  color: white !important;
  font-size: 9px;
  padding: 10px;
  justify-content: center;
  text-align: center;
}
.iconBtnPG {
  font-size: 20px;
}
.icon-info {
  font-size: 40px;
}
.nameParam2 {
  width: 30%;

  display: flex;
}
.nameParam2 input {
  font-size: 1.5em;
}
</style>
