<template>
  <v-card
    elevation="0"
    :class="mdAndUp ? 'overflow-auto py-3 scroll' : 'mt-10 mb-10'"
    color="transparent"
    :height="right_sheet_height"
    width="100%"
  >
    <v-row
      dense
      :class="mdAndUp ? 'text-justify mr-5 ml-4' : 'text-justify mx-3'"
      style="height: 100%"
    >
      <v-col cols="12" md="12" class="d-flex flex-column justify-space-around">
        <h3 class="text-uppercase" style="color: #34495e">
          {{ "culturas predominantes" }}
        </h3>
        <div v-for="(item, index) in archetypes_titles" :key="item">
          <h4 class="aspects">{{ item }}</h4>
          <p class="description">
            {{ archetypes_text[index] }}
          </p>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { useDisplay } from "vuetify";
import { computed } from "vue";
import { mapState } from "vuex";

export default {
  name: "DashboardDataDescriptionSection",

  data: () => ({}),
  computed: {
    ...mapState([
      "culture_profile_chart",
      "archetypes_titles",
      "archetypes_text",
    ]),
  },

  methods: {},

  created() {},
  setup() {
    // Destructure only the keys we want to use
    const { lgAndUp } = useDisplay();
    const { mdAndUp } = useDisplay();

    const { name } = useDisplay();

    const right_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (92 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (94 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (94 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (94 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    return { lgAndUp, mdAndUp, right_sheet_height };
  },
};
</script>

<style>
.description {
  color: #585858;
  font-size: 12px;
}

.aspects {
  color: #34495e;
}

.scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scroll::-webkit-scrollbar:vertical {
  width: 10px;
}

.scroll::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none;
}

.scroll::-webkit-scrollbar:horizontal {
  height: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #00c3c0;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}
</style>
