<template>
  <div :class="mdAndUp ? 'd-flex flex-row' : ''">
    <v-sheet
      color="transparent"
      :height="left_sheet_height"
      :width="left_sheet_width"
    >
      <v-sheet
        height="100%"
        width="100%"
        :color="mdAndUp ? 'white' : 'transparent'"
        class="d-flex align-center"
        :elevation="mdAndUp ? '2' : '0'"
      >
        <DashboardButtonsSection />
      </v-sheet>
    </v-sheet>
    <v-sheet
      color="transparent"
      :height="left_sheet_height"
      :width="right_sheet_width"
    >
      <v-sheet :height="right_sheet_height" width="100%" color="transparent">
        <v-row dense class="row">
          <v-col cols="12" md="8">
            <DashboardChartSection :titles="titles" />
          </v-col>
          <v-col cols="12" md="4">
            <DashboardDataDescriptionSection />
          </v-col>
        </v-row>
      </v-sheet>

      <!--Responsive-->
      <v-sheet
        color="transparent"
        :height="left_sheet_height"
        :width="left_sheet_width"
        class="hidden-md-and-up mb-10"
      >
        <v-sheet
          height="100%"
          width="100%"
          color="transparent"
          class="d-flex align-center hidden-md-and-up"
        >
          <DashboardButtonsSectionMobile />
        </v-sheet>
      </v-sheet>

      <!--Footer-->
      <v-sheet
        :height="footer_height"
        width="100%"
        color="transparent"
        :class="mdAndUp ? 'd-flex align-center' : 'd-flex align-center mb-3'"
      >
        <v-row wrap no-gutters>
          <v-col cols="12" md="12">
            <div class="text-justify footer bg-transparent w-100 h-100">
              <p :class="mdAndUp ? 'mx-6' : 'mx-3'">
                {{ footer }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-sheet>
  </div>

  <!--   <v-dialog
    v-model="test_dialog"
    width="auto"
    transition="dialog-top-transition"
  >
    <v-card
      class="d-flex justify-center text-center"
      :width="mdAndUp ? 400 : 'auto'"
    >
      <v-toolbar color="transparent" height="50">
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            icon
            @click="setTest_dialog_false"
            :ripple="false"
            variant="text"
          >
            <v-icon color="#34495e">mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container fluid> -->
  <!--Title Dialog-->
  <!--    <v-card-title class="" style="color: #34495e">
          <h4>Usted no ha hecho el test</h4>
        </v-card-title>

        <v-card-text> -->
  <!--Buttons Dialog-->
  <!--    <v-row dense class="d-flex justify-center">
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                block
                rounded="lg"
                density="comfortable"
                size="x-large"
                class="dotest_button mt-5 mb-1 px-16"
                :href="internal_link"
                target="_blank"
              >
                {{ dotest_button }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
  </v-dialog> -->
</template>

<script>
import { defineComponent } from "vue";
import { computed } from "vue";
import { useDisplay } from "vuetify";

//service
import DashboardService from "@/services/DashboardService";
import LoginService from "@/services/LoginService";

// Components
import DashboardButtonsSectionMobile from "@/components/DashboardComponents/CorporateCultureDiagnosis/DashboardButtonsSectionMobile.vue";
import DashboardButtonsSection from "@/components/DashboardComponents/CorporateCultureDiagnosis/DashboardButtonsSection.vue";
import DashboardChartSection from "@/components/DashboardComponents/CorporateCultureDiagnosis/DashboardChartSection.vue";
import DashboardDataDescriptionSection from "@/components/DashboardComponents/CorporateCultureDiagnosis/DashboardDataDescriptionSection.vue";
import { mapMutations, mapState } from "vuex";

export default defineComponent({
  name: "DashboardView",
  data() {
    return {
      height: window.innerHeight,

      dotest_button: "HACER TEST",

      //Data

      //Org SpiderChart Data
      graf1_1_1: [],

      pAorg: [],

      //Team SpiderChart Data
      pAind: [],

      //Charts Categories
      arqTITULO: [],

      /*    //Predominant Categories Descriptions
      arqTITULOpred: [], */

      //Charts Categories Descriptions
      arqTEXTO: [],

      //Received Answers
      filas: "",

      //Link to copy
      linkInterno: "",

      titles: {
        chart: {
          type: "radar",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            style: {
              colors: [
                "#34495e",
                "#34495e",
                "#34495e",
                "#34495e",
                "#34495e",
                "#34495e",
              ],
              fontSize: "11px",
              fontFamily: "Arial",
            },
          },
        },
        legend: {
          show: "true",
          position: "top",
        },

        yaxis: {
          show: false,
        },
        stroke: {
          width: 1,
          colors: ["#00C3C0"],
        },
        markers: {
          size: 0,
        },
        fill: {
          colors: ["#00C3C0"],
        },
      },

      footer: "",
    };
  },

  computed: {
    ...mapState(["user_info", /* "test_dialog", */ "internal_link"]),
  },

  methods: {
    ...mapMutations([
      "setOrg_serie",
      "setTeam_serie",
      "setArchetypes_titles",
      "setArchetypes_text",
      "setSummary",
      "setArchetype_predominance",
      "setInternal_link",
      "setSpeedo_percent",
      "setUser_tokens",
      /*   "setTest_dialog_false", */
      /* "setTest_dialog_true", */
      "getUserFromSessionStorage",
      "setAllowDownloadReport",
    ]),

    async getUserToken() {
      try {
        const response = await LoginService.getUserToken(this.user_info.email);
        let data = response.data;

        this.setUser_tokens(data);
      } catch (error) {
        console.log(error);
      }
    },

    async loadDashboard() {
      try {
        const response = await DashboardService.loadDashboard(
          this.user_info.tokens.tokenDC,
          "es"
        );
        let data = response.data;

        if (data && data.report !== undefined) {
          this.setAllowDownloadReport(data.report == 1 ? true : false);
        }

        if (data.filas == 0) {
          this.setInternal_link(data.linkInterno);
          this.setSpeedo_percent(0);
          this.setSummary(data.filas);

          let predominance = [];
          for (let index = 0; index < data.arqTITULO.length; index++) {
            let obj = {
              title: data.arqTITULO[index],
              value: index,
            };

            predominance.push(obj);
          }

          this.setOrg_serie([0, 0, 0, 0, 0, 0]);
          this.setTeam_serie([0, 0, 0, 0, 0, 0]);

          this.setArchetype_predominance(predominance);

          this.setArchetypes_text(data.arqTEXTO);

          /*   this.setTest_dialog_true(); */
        } else {
          this.setOrg_serie(data.graf1_1_1);
          this.setTeam_serie([data.graf1_2_1, data.graf1_2_2]);

          //arque top2 cultura coporativa
          this.setArchetypes_titles(data.arqTITULOpred);
          this.setArchetypes_text(data.arqTEXTOpred);
          // Predominancia de tabla de empresa
          let predominance = [];
          for (let index = 0; index < data.arqTITULO.length; index++) {
            let obj = {
              title: data.arqTITULO[index],
              value: data.graf1_1_1[index],
            };

            predominance.push(obj);
          }

          this.setArchetype_predominance(predominance);
          this.setSummary(data.filas);
          this.setInternal_link(data.linkInterno);

          this.setSpeedo_percent(data.kpi1_1);
        }
      } catch (error) {
        console.log(error);
      }
    },

    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);
      this.footer = texts[62];
    },
  },

  async created() {
    this.getUserFromSessionStorage();
    this.set_texts();
    await this.loadDashboard();
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const left_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (92 * window.innerHeight) / 100;
        case "lg":
          return (92 * window.innerHeight) / 100;
        case "xl":
          return (92 * window.innerHeight) / 100;
        case "xxl":
          return (92 * window.innerHeight) / 100;
      }

      return undefined;
    });
    const left_sheet_width = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (21 * window.innerWidth) / 100;
        case "lg":
          return (20 * window.innerWidth) / 100;
        case "xl":
          return (21 * window.innerWidth) / 100;
        case "xxl":
          return (21 * window.innerWidth) / 100;
      }

      return undefined;
    });

    const right_sheet_width = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (82 * window.innerWidth) / 100;
        case "lg":
          return (82 * window.innerWidth) / 100;
        case "xl":
          return (82 * window.innerWidth) / 100;
        case "xxl":
          return (82 * window.innerWidth) / 100;
      }

      return undefined;
    });

    const right_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (94 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (94 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (94 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (94 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    const footer_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (6 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (6 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (6 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (6 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return {
      left_sheet_height,
      left_sheet_width,
      right_sheet_height,
      right_sheet_width,
      footer_height,
      mdAndUp,
      xsAndUp,
    };
  },

  components: {
    DashboardButtonsSection,
    DashboardDataDescriptionSection,
    DashboardChartSection,
    DashboardButtonsSectionMobile,
  },
});
</script>

<style scoped>
.row {
  height: 100% !important;
}

.row1 {
  height: "(45*100vh)/100" !important;
}
.footer {
  font-size: 60%;
  font-style: italic;
  color: #585858;
}

.dotest_button {
  background-color: #34495e;
  color: white;
}
</style>
