<template>
  <v-sheet color="transparent" :height="bottom_sheet_height">
    <v-row dense style="height: 100%" class="">
      <PredValuesBehavior />
    </v-row>
  </v-sheet>
</template>

<script>
import { defineComponent } from "vue";
import { computed } from "vue";
import { useDisplay } from "vuetify";

// Components

import { mapState } from "vuex";

import PredValuesBehavior from "@/components/DashboardComponents/ProfilesEvaluation/PredValuesBehavior.vue";
export default defineComponent({
  name: "CandidatePredValuesView",
  props: {},
  components: {
    PredValuesBehavior,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState([]),
  },

  methods: {},

  created() {},

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const principles_card_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (90 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (90 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (92 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (92 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    const charts_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (87 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (87 * ((50 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }

      return undefined;
    });

    const left_sheet_width = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (65 * window.innerWidth) / 100;
        case "lg":
          return (65 * window.innerWidth) / 100;
        case "xl":
          return (65 * window.innerWidth) / 100;
        case "xxl":
          return (65 * window.innerWidth) / 100;
      }

      return undefined;
    });

    const right_sheet_width = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (35 * window.innerWidth) / 100;
        case "lg":
          return (35 * window.innerWidth) / 100;
        case "xl":
          return (35 * window.innerWidth) / 100;
        case "xxl":
          return (35 * window.innerWidth) / 100;
      }

      return undefined;
    });

    const left_top__sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (45 * ((80 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (45 * ((80 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (50 * ((85 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (50 * ((85 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }

      return undefined;
    });

    const left_bottom_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (55 * ((80 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (55 * ((80 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (50 * ((85 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (55 * ((85 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }

      return undefined;
    });

    const bottom_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (80 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (85 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (85 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (85 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return {
      bottom_sheet_height,
      right_sheet_width,
      left_sheet_width,
      left_bottom_sheet_height,
      left_top__sheet_height,
      principles_card_height,
      charts_sheet_height,
      mdAndUp,
      xsAndUp,
    };
  },
});
</script>
