<template>
  <v-app-bar fixed elevate-on-scroll :elevation="0" :height="app_bar_height">
    <!--Avatar Cultural Fit in colors-->
    
      <v-avatar
        :class="mdAndUp ? 'ml-15' : 'ml-3'"
        color="white"
        size="230"
        rounded="0"
        style="cursor: pointer"
        @click="open_main_page"
      >
        <v-img src="@/assets/Logo.png" max-width="100%" contain> </v-img>
      </v-avatar>
  
    <v-spacer></v-spacer>

    <div class="d-flex align-end">
      <!--External Links-->
      <v-btn
        variant="text"
        class="bar_button font-weight-bold text-none hidden-md-and-down"
        :href="contact_link"
        target="_blank"
      >
        {{ contact }}
      </v-btn>
    </div>

    <!--Link to this page-->
    <v-btn
      density="comfortable"
      class="bar_button_selected font-weight-bold px-15 mr-15 hidden-md-and-down"
    >
      {{ login }}
    </v-btn>

    <!--Menu responsive to device md and down with the links-->
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          class="hidden-lg-and-up"
          size="x-large"
          icon="mdi-menu"
        >
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          :value="link.title"
          class="bar_button text-center"
          :href="contact_link"
          target="_blank"
        >
          <v-list-item-title>{{ contact }}</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-btn
            density="comfortable"
            block
            class="bar_button_selected font-weight-bold px-15"
          >
            {{ login }}
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
  
  <script>
import Links from "@/globals/Links";
import { useDisplay } from "vuetify";

export default {
  name: "LoginAppBar",

  data: () => ({
    //Buttons
    contact: "",
    contact_link: "",
    login: "",
    main_page_link: "",

    app_bar_height: (12 * window.innerHeight) / 100,
  }),

  computed: {
    showMenu() {
      return this.toggleMenu || this.$vuetify.breakpoint.lgAndUp;
    },
  },

  methods: {
    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);
      this.contact = texts[1];
      this.login = texts[0];
      this.contact_link = Links.contact_link;
      this.main_page_link = Links.main_page_link;
    },

    open_main_page() {
        let win = window.open(this.main_page_link, '_blank');
        win.focus();
      }
  },

  created() {
    this.set_texts();
  },

  setup() {
    // Destructure only the keys we want to use
    const { lgAndUp } = useDisplay();
    const { mdAndUp } = useDisplay();

    return { lgAndUp, mdAndUp };
  },
};
</script>

<style scoped>
.app_bar {
  height: 12vh !important;
  align-content: end;
}
.bar_button {
  color: #34495e;
  font-size: 14px;
}

.bar_button_selected {
  background-color: #34495e;
  color: white;
  font-size: 14px;
}
</style>