<script setup>
import { useStore } from "vuex";
import DictionaryForReport from "@/globals/DictionaryForReport";
import { computed } from "vue";

const store = useStore();
const Low = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][64];
});
const Standard = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][65];
});
const High = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][66];
});
const LessThan70 = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][67];
});
const From70To80 = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][68];
});

const MoreThan80 = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][69];
});
</script>

<template>
  <div class="kpi-legend">
    <div class="legend-titles">
      <span>{{ Low }}</span>
      <span>{{ Standard }}</span>
      <span>{{ High }}</span>
    </div>
    <div class="legend-ranges">
      <div class="legend-value">
        <div class="low-range"></div>
        <div class="text">{{ LessThan70 }}</div>
      </div>
      <div class="legend-value">
        <div class="mid-range"></div>
        <div class="text">{{ From70To80 }}</div>
      </div>
      <div class="legend-value">
        <div class="high-range"></div>
        <div class="text">{{ MoreThan80 }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.kpi-legend {
  width: 362px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  .legend-titles,
  .legend-ranges {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;

    span {
      font-size: calc(8px * 1.333);
      font-weight: bold;
      color: #000;
      text-transform: uppercase;
    }
  }

  .legend-ranges {
    border: 1px solid #000;
    border-radius: 24px;

    .legend-value {
      padding: 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4px;

      .low-range {
        width: 28px;
        height: 6px;
        background-color: #f7e66b;
        border-radius: 12px;
        padding: 0px;
      }

      .mid-range {
        width: 28px;
        height: 6px;
        background-color: #cedebb;
        border-radius: 12px;
        padding: 0px;
      }

      .high-range {
        width: 28px;
        height: 6px;
        background-color: #b7cf9b;
        border-radius: 12px;
        padding: 0px;
      }

      .text {
        font-size: calc(8px * 1.333);
        color: rgba(0, 0, 0, 0.87);
        padding: 0px;
      }
    }
  }
}
</style>
