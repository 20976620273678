<template>
  
   <!--Main Container with the picture and forms-->
   <LoginCard />
 
    
</template>

  <script>
import { defineComponent } from "vue";

// Components
import LoginCard from "@/components/LoginComponents/LoginCard.vue";

export default defineComponent({
  name: "LoginView",

  components: {
    LoginCard,
  },
  data (){
    return{
     
    }
  }
});
</script>
<style scoped>

</style>