<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { defineProps } from "vue";
import DictionaryForReport from "@/globals/DictionaryForReport";
import greenCircleImage from "@/assets/report-images/green-circle.png";
import diagonalLinesImage from "@/assets/report-images/diagonal-lines.png";
import dotsImage from "@/assets/report-images/dots.png";
import cfamLogoImage from "@/assets/report-images/cfam-logo.png";
import linesImage from "@/assets/report-images/lines.png";

const store = useStore();

defineProps({
  reportData: Object,
});

const profileReportTitle = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][1];
});
const footerText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][3];
});
const DateText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][63];
});
</script>

<template>
  <div id="coverPage" class="page">
    <div class="page-body">
      <img :src="greenCircleImage" class="page1-green-circle" />
      <img :src="diagonalLinesImage" class="page1-diagonal-lines" />
      <img :src="dotsImage" class="page1-dots" />

      <img :src="cfamLogoImage" class="page1-cfam-logo" />
      <img :src="linesImage" class="page1-lines-logo" />
      <div class="cover-data">
        <div class="title">{{ profileReportTitle }}</div>
        <div class="green-field mt-20">{{ reportData.name }}</div>
        <div class="small-dark-green-field">
          <strong>ID:</strong> {{ reportData.tokenText }}
        </div>
        <div class="small-dark-green-field">
          <strong>E-mail:</strong> {{ reportData.email.replace("CF-", "") }}
        </div>
      </div>
      <div class="cover-green-rect"></div>
      <div class="cover-dark-green-rect"></div>
      <div class="cover-date small-dark-green-field">
        <strong>{{ DateText }}:</strong>
        {{ new Date().toLocaleDateString("es-ES") }}
      </div>
    </div>
    <div class="page-footer">
      {{ footerText }}
    </div>
  </div>
</template>

<style scoped>
.page1-green-circle {
  display: block;
  width: 180px;
  position: absolute;
  left: 32px;
}

.page1-diagonal-lines {
  display: block;
  width: 168px;
  position: absolute;
  margin-top: 32px;
  right: 32px;
}

.page1-dots {
  display: block;
  width: 183px;
  position: absolute;
  margin-top: 166px;
  left: 88px;
}

.page1-cfam-logo {
  display: block;
  width: 289px;
  position: absolute;
  margin-top: 296px;
  right: 205px;
}

.page1-lines-logo {
  display: block;
  width: 58px;
  position: absolute;
  margin-top: 514px;
  left: 129px;
}

.cover-data {
  position: absolute;
  left: 290px;
  margin-top: 690px;
  text-align: center;
}

.cover-date {
  position: absolute;
  right: 116px;
  margin-top: 913px;
  text-align: right;
}

.cover-green-rect {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 65px;
  margin-top: 860px;
  background-color: #2eb6b9;
}

.cover-dark-green-rect {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 54px;
  margin-top: 818px;
  background-color: #33495f;
}
</style>
