<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import DictionaryForReport from "@/globals/DictionaryForReport";
import greenCircleImage from "@/assets/report-images/green-circle.png";
import greenCircleRotatedImage from "@/assets/report-images/green-circle-rotated.png";
import verticalLinesImage from "@/assets/report-images/vertical-lines.png";
import sixCulturesImage from "@/assets/report-images/6-cultures.png";
import EnglishsixCulturesImage from "@/assets/report-images/6-cultures-english.png";
import collaboratorLogosImage from "@/assets/report-images/collaborator-logos.png";
const store = useStore();

const SixCulturesImage = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return currentLanguage === "en" ? EnglishsixCulturesImage : sixCulturesImage;
});

const SixCulturesTitle = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][4];
});
const FirstParagraph = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][5];
});
const SecondParagraph = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][6];
});
const ThirdParagraph = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][7];
});
const Citation = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][8];
});

const CoverFooter = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][3];
});
</script>

<template>
  <div id="page2" class="page">
    <div class="page-body">
      <img :src="greenCircleImage" class="page2-green-circle" />
      <img :src="greenCircleRotatedImage" class="page2-green-circle-rotated" />

      <div class="text-container mt-130">
        <div class="title">{{ SixCulturesTitle }}</div>
        <p class="small-dark-green-field" v-html="FirstParagraph"></p>
        <p class="small-dark-green-field" v-html="SecondParagraph"></p>
      </div>

      <img :src="SixCulturesImage" class="page2-6-cultures" />
      <img :src="verticalLinesImage" class="page2-vertical-lines" />

      <div class="text-container mt-20">
        <p class="small-dark-green-field" v-html="ThirdParagraph"></p>
      </div>

      <img :src="collaboratorLogosImage" class="page2-collaborator-logos" />

      <div class="citation mt-160" v-html="Citation"></div>
    </div>
    <div class="page-footer">
      {{ CoverFooter }}
    </div>
  </div>
</template>

<style scoped>
.page2-green-circle {
  display: block;
  width: 180px;
  position: absolute;
  left: 377px;
}

.page2-green-circle-rotated {
  display: block;
  height: 180px;
  position: absolute;
  margin-top: 36px;
  right: 0px;
}

.page2-6-cultures {
  width: 100%;
  margin-top: 400px;
  position: relative;
}

.page2-collaborator-logos {
  width: 520px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  position: absolute;
  display: block;
  margin-top: 80px;
}

.page2-vertical-lines {
  position: absolute;
  right: 40px;
  margin-top: 750px;
  width: 40px;
  z-index: -1;
}
</style>
