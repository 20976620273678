import Dictionary from "@/globals/Dictionary";
import Archetypes from "@/globals/Archetypes";
import EthicArchetypes from "@/globals/EthicArchetypes";
import LeaderValues from "./globals/LeaderValues";
import LeaderValues2 from "./globals/LeaderValues2";

export async function select_leng() {
  try {
    let current_leng = JSON.stringify(Dictionary.spanish);
    sessionStorage.setItem("texts", current_leng);

    let archetypes = JSON.stringify(Archetypes.spanish);
    sessionStorage.setItem("archetypes", archetypes);

    let ethic_archetypes = JSON.stringify(EthicArchetypes.spanish);
    sessionStorage.setItem("ethic_archetypes", ethic_archetypes);

    let leader_values = JSON.stringify(LeaderValues.spanish);
    sessionStorage.setItem("leader_values", leader_values);

    let leader_values2 = JSON.stringify(LeaderValues2.spanish);
    sessionStorage.setItem("leader_values2", leader_values2);
  } catch (error) {
    console.error("Error in select_leng:", error);
    throw error; // Rethrow the error to handle it in main.js
  }
}
