<template>
  <v-form v-model="form" aria-autocomplete="false">
    <!--User Information Section-->
    <v-row class="pa-0">
      <v-col cols="4" md="3" class="pa-0">
        <p class="sections_title">{{ section1_title }}</p>
      </v-col>
      <v-col cols="8" md="9" class="pa-0">
        <span class="custom-line mt-3"></span>
      </v-col>
    </v-row>

    <v-row class="pa-0">
      <!--Full Name-->
      <v-col cols="12" class="pa-0">
        <v-text-field
          v-model="user_info.full_name"
          required
          class="text-fields mt-2"
          density="compact"
          type="text"
          bg-color="#BDF0EE"
          :label="full_name_label"
          variant="flat"
          single-line
          hide-details
          disabled="true"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0" class="mr-2">mdi mdi-account</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <!--Company Name-->
      <v-col cols="12" class="pa-0">
        <v-text-field
          v-model="user_info.company_name"
          required
          class="text-fields mt-3"
          density="compact"
          type="text"
          bg-color="#BDF0EE"
          :label="company_name_label"
          variant="flat"
          single-line
          hide-details
          disabled="true"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0" class="mr-2">mdi mdi-domain</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <!--Position-->
      <v-col cols="12" class="pa-0">
        <v-text-field
          v-model="user_info.position"
          required
          class="text-fields mt-3"
          density="compact"
          type="text"
          bg-color="#BDF0EE"
          :label="position_label"
          variant="flat"
          single-line
          hide-details
          disabled="true"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0" class="mr-2">mdi mdi-account-tie</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <!--Email-->
      <v-col cols="12" class="pa-0">
        <v-text-field
          v-model="user_info.email"
          required
          class="text-fields mt-3"
          density="compact"
          type="email"
          bg-color="#BDF0EE"
          :label="email_label"
          variant="flat"
          single-line
          hide-details
          disabled="true"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0" class="mr-2">mdi mdi-email</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <!--Phone-->
      <v-col cols="12" class="pa-0">
        <v-text-field
          v-model="user_info.phone"
          required
          class="text-fields mt-3"
          density="compact"
          type="text"
          bg-color="#BDF0EE"
          :label="phone_label"
          variant="flat"
          single-line
          hide-details
          disabled="true"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0" class="mr-2">mdi mdi-phone</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>

    <!--Password Section-->
    <v-row class="pa-0 mt-7">
      <v-col cols="3" md="2" class="pa-0">
        <p class="sections_title">{{ section2_title }}</p>
      </v-col>
      <v-col cols="9" md="10" class="pa-0">
        <span class="custom-line mt-3"></span>
      </v-col>
    </v-row>

    <v-row class="pa-0">
      <!--New Password-->
      <v-col cols="12" class="pa-0">
        <v-text-field
          v-model="new_password"
          required
          class="text-fields mt-2"
          density="compact"
          :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          @click:append-inner="show1 = !show1"
          bg-color="#BDF0EE"
          :label="new_password_label"
          variant="flat"
          single-line
          hide-details
          :rules="fieldsRules"
          autocomplete="new-password"
          @keydown.enter="save_changes"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0" class="mr-2">mdi mdi-lock-plus</v-icon>
          </template>
        </v-text-field>
      </v-col>

      <!--New Password Repeat-->
      <v-col cols="12" class="pa-0">
        <v-text-field
          v-model="new_password_repeat"
          required
          class="text-fields mt-2"
          density="compact"
          :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show2 ? 'text' : 'password'"
          @click:append-inner="show2 = !show2"
          bg-color="#BDF0EE"
          :label="new_password_repeat_label"
          variant="flat"
          single-line
          hide-details
          :rules="fieldsRules"
          autocomplete="off"
          @keydown.enter="save_changes"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0" class="mr-2">mdi mdi-lock-check</v-icon>
          </template>
        </v-text-field>
        <div class="text-center">
          <p class="mt-2" style="color: #585858; font-size: 10px">
            {{password_requirements}}
          </p>
        </div>
      </v-col>
    </v-row>

    <!--Save Button-->
    <v-row class="pa-0">
      <v-col cols="12" class="pa-0">
        <v-btn
          block
          rounded="lg"
          :disabled="!form"
          density="comfortable"
          size="large"
          id="save"
          :class="
            mdAndUp
              ? 'font-weight-bold save_button mt-10'
              : 'font-weight-bold save_button mt-10 mb-10'
          "
          @click="save_changes"
        >
          {{ save_btn }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>

  <v-snackbar
    v-model="snackbar"
    :timeout="2000"
    variant="elevated"
    class="mb-5"
    location="bottom right"
    :color="color"
    width="auto"
    transition="scroll-x-reverse-transition"
  >
    <h3 class="text-white">
      <v-icon color="white" class="mr-1" size="30">mdi mdi-check-circle</v-icon>
      {{ snack_message }}
    </h3>
  </v-snackbar>
</template>
  
<script>
import { mapState } from "vuex";
import { ROUTES } from "/src/router/names";
import { useDisplay } from "vuetify";
import { computed } from "vue";

import UserService from "@/services/UserService";

export default {
  name: "RecoverPasswordForm",
  components: {},

  data: () => ({
    // Form data
    form: false,

    //Show Password
    show1: false,
    show2: false,

    //Snackbar Notification
    snackbar: false,
    snack_message: "",
    color: "",

    //Sections Title
    section1_title: "",
    section2_title: "",

    //Save Button
    save_btn: "Guardar",

    //Text Field Model
    new_password: "",
    new_password_repeat: "",

    //Text Field labels
    full_name_label: "Nombre Completo",
    email_label: "E-mail Corporativo",
    company_name_label: "Nombre de la empresa",
    position_label: "Cargo",
    phone_label: "Teléfono de Contacto",
    password_label: "Contraseña",
    new_password_label: "Nueva Contraseña",
    new_password_repeat_label: "Confirmar Contraseña",
    password_requirements : "",


    //Text Fields Rules
    fieldsRules: [(v) => !!v || "Usuario requerido"],

    ROUTES: ROUTES,
  }),

  computed: {
    ...mapState(["user_info"]),
  },

  created(){
    this.set_texts();
  },

  methods: {

    set_texts (){
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);

      this.section1_title = texts[28];
      this.section2_title = texts[9];
      this.save_btn = texts[32];
      this.full_name_label = texts[6];
      this.email_label = texts[7];
      this.company_name_label = texts[8];
      this.position_label = texts[29];
      this.phone_label = texts[30];
      this.new_password_label = texts[31];
      this.new_password_repeat_label = texts[10];
      this.password_requirements = texts[11];
    },

    validPassword(password) {
      var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@$!%*?&_.]{6,20}/;
      return re.test(password);
    },

    save_changes() {
      if (this.new_password == this.new_password_repeat) {
        if (this.validPassword(this.new_password)) {
          this.updateUser();
        } else {
          this.snack_message =
            "Contraseña muy débil. Debe cumplir con los requisitos";
          this.snackbar = true;
          this.new_password = "";
          this.new_password_repeat = "";
          this.color = "error";
        }
      } else {
        this.snack_message =
          "Las contraseñas no coinciden. Vuelva a intentarlo.";
        this.snackbar = true;
        this.new_password = "";
        this.new_password_repeat = "";
        this.color = "error";
      }
    },

    async updateUser() {
      let formData = new FormData();
      formData.append("email", this.user_info.email);
      formData.append("password", this.new_password);

      try {
        const response = await UserService.updateUser(
          formData,
          this.user_info.authorisation_token
        );
        if (response.status == 200) {
          this.$router.replace(ROUTES.LOGIN);
          this.new_password = "";
          this.new_password_repeat = "";
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { smAndDown } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const card_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (92 * ((88 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (90 * ((88 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (90 * ((88 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (90 * ((88 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    const card_width = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "90%";
        case "sm":
          return "90%";
        case "md":
          return (85 * window.innerWidth) / 100;
        case "lg":
          return (75 * window.innerWidth) / 100;
        case "xl":
          return (75 * window.innerWidth) / 100;
        case "xxl":
          return (75 * window.innerWidth) / 100;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return { card_height, card_width, mdAndUp, xsAndUp, smAndDown };
  },
};
</script>

<style scoped>
.text-fields {
  font-style: italic;
  font-weight: bold;
}

.save_button {
  background-color: #34495e;
  color: white;
}

.text-fields :-webkit-autofill,
.text-fields :-webkit-autofill:hover,
.text-fields :-webkit-autofill:focus,
.text-fields :-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #585858 !important;
}

.text-fields .v-text-field--outlined >>> fieldset {
  border-color: rgba(192, 0, 250, 0.986);
}

.sections_title {
  color: #34495e;
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
}

.custom-line {
  border: 1px solid #00c3c0;
  display: block;
}
</style>