<script setup>
import { defineProps, computed } from "vue";

import { useStore } from "vuex";
import DictionaryForReport from "@/globals/DictionaryForReport";

defineProps({
  reportData: Object,
});
const store = useStore();

const PredValuesTitle = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][12];
});
const AlsoIdentify = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][23];
});
const TitlePValues = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][24];
});
const TitleEBehaviors = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][25];
});
const TitleECompetences = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][26];
});

const footerText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][3];
});
</script>

<template>
  <div id="predominantValues" class="page">
    <div class="page-body">
      <div class="main-container">
        <div class="title">{{ PredValuesTitle }}</div>
        <p class="small-dark-green-field" v-html="AlsoIdentify"></p>

        <v-table v-bind="props">
          <tr>
            <td class="dark-green">{{ TitlePValues }}</td>
            <td class="dark-green">{{ TitleEBehaviors }}</td>
            <td class="dark-green">{{ TitleECompetences }}</td>
          </tr>
          <tr v-for="(value, key) in reportData.predominantValues" :key="key">
            <td class="light-green-lt">{{ value.title }}</td>
            <td class="regular-cell">{{ value.text }}</td>
            <td class="regular-cell">{{ value.competences }}</td>
          </tr>
        </v-table>
      </div>
    </div>
    <div class="page-footer">
      {{ footerText }}
    </div>
  </div>
</template>

<style scoped></style>
