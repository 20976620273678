<template>
  <!--Dialog to delete a tag-->
  <v-dialog
    v-model="dialog"
    activator="parent"
    :width="mdAndUp ? '35%' : '90%'"
  >
    <v-card class="d-flex justify-center text-center">
      <v-container>
        <!--Title Dialog-->

        <p class="text-caption mt-3" style="color: #34495e; font-size: 14px !important">
          {{ subtitle1 }}
        </p>

        <v-card-title class="mt-3" style="color: #00c3c0">
          {{ tag.texto }}
        </v-card-title>

        <p class="mt-3 text-caption" style="color: #34495e; font-size: 14px !important">
          {{ subtitle2 }}
        </p>
        <v-card-text>
          <!--Buttons Dialog-->
          <v-row dense class="mt-3">
            <v-col cols="12">
              <!--Dialog Cancel Button -->
              <v-btn @click="dialog = false" class="mr-3 cancel_btn">
                {{ cancel_btn }}
              </v-btn>
              <!--Dialog Save Button -->
              <v-btn color="error" @click="deleteTag">
                {{ delete_btn }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { mapMutations } from "vuex";
import { useDisplay } from "vuetify";

//API Service
import TagsService from "@/services/TagsService";
import ProfileEvaluationService from "@/services/ProfileEvaluationService";

// Components

export default defineComponent({
  name: "DeleteTagDialog",
  props: {
    tag: {
      type: Object,
    },
  },
  data() {
    return {
      //New Label Dialog
      dialog: false,

      //Buttons Dialog
      cancel_btn: "",
      delete_btn: "",

      //Dialog Title
      subtitle1: "",
      subtitle2: "",
    };
  },
  components: {
    //CandidatesTable,
  },

  computed: {
    ...mapState(["tags_list", "newtag_dialog", "user_info"]),
  },

  methods: {
    ...mapMutations(["setTags_list", "setCandidates", "delete_tag"]),

    set_texts (){
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);

      this.subtitle1 = texts[79];
      this.subtitle2 = texts[80];
      this.cancel_btn = texts[36];
      this.delete_btn = texts[78];
      
    },

    //On Delete Tag
    async deleteTag() {
      let formData = new FormData();
      formData.append("id", this.tag.id);

      try {
        const response = await TagsService.deleteTag(formData);
        let data = response.data;
        console.log(data);

        this.loadAllTags();
        this.loadCandidatesGeneralView();
        this.dialog = false;
      } catch (error) {
        console.log(error);
      }
    },

    //Aux
    async loadAllTags() {
      try {
        const response = await TagsService.getAllTags(
          this.user_info.tokens.tokenPE
        );
        let data = response.data;

        this.setTags_list(data);
      } catch (error) {
        console.log(error);
      }
    },

    async loadCandidatesGeneralView() {
      try {
        const response =
          await ProfileEvaluationService.loadCandidatesGeneralView(
            this.user_info.tokens.tokenPE,
            "es"
          );
        var data = response.data;

        this.setCandidates(data);
      } catch (error) {
        console.log(error);
      }
    },
  },

  created(){
    this.set_texts();
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();

    return { mdAndUp };
  },
});
</script>

<style scoped>
.label_btn {
  background-color: #00c3c0;
  color: white;
}

.new_label_item {
  font-size: 16px;
  color: #34495e;
  background-color: #bdf0ee;
}

.newtag_tf {
  font-style: italic;
  font-weight: bold;
  background-color: #bdf0ee;
  width: auto;
}

.cancel_btn {
  background-color: #34495e;
  color: white;
}
</style>