<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <!--Top Row-->
  <v-row dense class="mx-3 mt-5">
    <!--Table Title-->
    <v-col cols="6" md="4" class="d-flex align-center">
      <div>
        <h3 class="text-uppercase" style="color: #34495e">
          {{ table_title }}
        </h3>
      </div>
    </v-col>

    <!--Tags Buttons-->
    <v-col cols="6" md="3" class="d-flex justify-end align-center">
      <TagsMenu />
    </v-col>

    <!--Filter Text Field-->
    <v-col cols="12" md="5" class="d-flex justify-end align-center">
      <v-text-field
        v-model="search"
        density="compact"
        variant="flat"
        :placeholder="search_placeholder"
        bg-color="white"
        hide-details
        class="search_tf mx-1"
      >
        <template v-slot:append-inner>
          <div
            style="height: 100%; position: absolute"
            class="filter_icon rounded d-flex align-center mt-n2 px-2 ml-n6"
          >
            <v-icon size="25" color="white" location="top right" class="  "
              >mdi mdi-magnify</v-icon
            >
          </div>
        </template>
      </v-text-field>
    </v-col>
  </v-row>

  <!--Table-->

  <v-card
    :elevation="0"
    color="white"
    class="overflow-auto mx-3 mt-8 mb- scroll"
    max-height="700"
  >
    <div class="d-flex flex-column mx-2">
      <template v-for="candidate in searchCandidate" :key="candidate">
        <v-card elevation="6" class="px-0 mb-5">
          <v-container class="">
            <v-row dense class="text-center">
              <v-col cols="6">
                <h6 class="text-small header">
                  {{ email_user }}
                </h6>
                <p class="text-small data">
                  {{ candidate.email_user }}
                </p>
              </v-col>

              <v-col cols="6">
                <h6 class="text-small header">
                  {{ full_name }}
                </h6>
                <p class="text-small data">
                  {{ candidate.name }}
                </p>
              </v-col>
            </v-row>
            <span class="custom-line mt-3 mb-2"></span>
            <v-row dense class="d-flex align-end text-center">
              <v-col cols="3">
                <h6 class="text-small header">
                  {{ person_org_fit }}
                </h6>
                <p class="text-small data">
                  {{ candidate.person_org_fit }}
                </p>
              </v-col>

              <v-col cols="3">
                <h6 class="text-small header">
                  {{ desired_org_fit }}
                </h6>
                <p class="text-small data">
                  {{ candidate.desired_org_fit }}
                </p>
              </v-col>

              <v-col cols="3">
                <h6 class="text-small header">
                  {{ dominant_individual_arq }}
                </h6>
                <p class="text-small data">
                  {{ candidate.dominant_individual_arq }}
                </p>
              </v-col>

              <v-col cols="3">
                <h6 class="text-small header">
                  {{ desired_org_culture }}
                </h6>
                <p class="text-small data">
                  {{ candidate.desired_org_culture }}
                </p>
              </v-col>
            </v-row>

            <span class="custom-line mt-3 mb-2"></span>

            <v-row dense class="d-flex justify-center text-center">
              <v-col cols="8">
                <h6 class="text-small header mb-1">
                  {{ tags }}
                </h6>
                <div v-if="candidate.tags.length === 0">
                  <v-menu location="bottom">
                    <template v-slot:activator="{ props }">
                      <v-chip
                        pill
                        v-bind="props"
                        link
                        size="small"
                        class="select_label_chip"
                        append-icon="mdi mdi-menu-down"
                      >
                        {{ tags_menu }}
                      </v-chip>
                    </template>
                    <v-card
                      elevation="2"
                      max-height="250"
                      class="overflow-auto scroll"
                    >
                      <v-list density="compact">
                        <template v-for="tag in tags_list" :key="tag">
                          <v-list-item
                            class=""
                            density="compact"
                            :value="tag"
                            active-color="#00c3c0"
                            rounded="lg"
                            @click="setTagToCandidate(candidate, tag)"
                          >
                            <v-list-item-title>
                              {{ tag.texto }}
                            </v-list-item-title>
                            <template v-slot:append>
                              <v-icon color="black">mdi mdi-plus</v-icon>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-card>
                  </v-menu>
                </div>

                <div v-else class="d-flex flex-row justify-center">
                  <div
                    v-if="candidate.tags.length === 1"
                    class="d-flex flex-row"
                  >
                    <template
                      v-for="tag in tags_to_display(candidate.tags, 1)"
                      :key="tag"
                    >
                      <v-chip
                        pill
                        closable
                        size="small"
                        class="chip mr-1"
                        close-icon="mdi mdi-close-circle-outline"
                        @click:close="deleteCandidateTag(candidate, tag)"
                      >
                        {{ tag.texto }}
                        <v-tooltip activator="parent" location="top">{{
                          tag.texto
                        }}</v-tooltip>
                      </v-chip>
                    </template>
                  </div>
                  <div v-else class="d-flex flex-row">
                    <template
                      v-for="tag in tags_to_display(candidate.tags, 2)"
                      :key="tag"
                    >
                      <v-chip
                        pills
                        closable
                        size="small"
                        class="chip mr-1"
                        close-icon="mdi mdi-close-circle-outline"
                        @click:close="deleteCandidateTag(candidate, tag)"
                      >
                        {{ tag_text_to_display(tag.texto) }}
                        <v-tooltip activator="parent" location="top">{{
                          tag.texto
                        }}</v-tooltip>
                      </v-chip>
                    </template>
                  </div>
                  <v-chip pill link size="small" class="select_label_chip">
                    <v-icon>mdi mdi-dots-horizontal</v-icon>
                    <CandidateTagsDialog :current_candidate="candidate" />
                  </v-chip>
                </div>
              </v-col>
              <v-col cols="4">
                <h6 class="text-small header mb-1">
                  {{ actions }}
                </h6>
                <v-btn
                  variant="text"
                  density="compact"
                  size="small"
                  icon="mdi mdi-file-download"
                  class="table_actions_icons"
                  @click="openPdfDialog(candidate)"
                >
                </v-btn>
                <v-btn
                  variant="text"
                  density="compact"
                  size="small"
                  icon="mdi mdi-eye"
                  class="table_actions_icons ml-2"
                  @click="candidate_details(candidate)"
                >
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
    </div>
  </v-card>

  <v-dialog v-model="pdfDialogVisible" persistent max-width="900px">
    <v-card style="position: relative">
      <v-card-title>
        <v-btn
          icon
          @click="pdfDialogVisible = false"
          style="position: absolute; top: 0; right: 0; margin: 10px"
          color="#01C3C0"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="width-100 d-flex justify-center align-center">
        <h3 style="text-decoration: underline">
          Tipos de informe de candidato a descargar
        </h3>
      </div>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <h4>
                Es posible descargar el
                <strong class="ment-inf">informe de mentalidad</strong> y los
                siguientes informes adicionales:
              </h4>
              <v-checkbox
                color="#01C3C0"
                label="Informe de Encaje cultural"
                v-model="opcionesReportDialog[0]"
              >
              </v-checkbox>
              <v-list-item-subtitle class="mt-n7">
                Opción que compara la cultura corporativa con el candidato
                <strong>
                  (Es necesario haber parametrizado la cultura
                  corporativa)</strong
                >
              </v-list-item-subtitle>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                color="#01C3C0"
                label="Informe de Comportamiento Ético"
                v-model="opcionesReportDialog[1]"
              ></v-checkbox>
              <v-list-item-subtitle class="mt-n7">
                Opción que incluye el índice de comportamiento ético del
                candidato
              </v-list-item-subtitle>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                color="#01C3C0"
                label="Informe de Liderazgo"
                v-model="opcionesReportDialog[2]"
              ></v-checkbox>
              <v-list-item-subtitle class="mt-n7">
                Opción que incluye el índice de liderazgo del candidato
              </v-list-item-subtitle>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          class="pdf-button"
          width="100%"
          raised
          color="#01C3C0"
          @click="confirmPdfCreation"
          >Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <CandidateReport ref="candidateReport" />
</template>

<script>
import { mapState, mapMutations } from "vuex";

//API Services
import ProfileEvaluationService from "@/services/ProfileEvaluationService";
import TagsService from "@/services/TagsService";

import CandidateTagsDialog from "./CandidateTagsDialog.vue";
import TagsMenu from "@/components/DashboardComponents/ProfilesEvaluation/TagsMenu.vue";
import CandidateReport from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReport.vue";

export default {
  name: "CandidatesDesktopTable",
  props: {
    itemsPerPage: {
      type: Number,
    },
    candidates_count: {
      type: Number,
    },
  },
  components: {
    TagsMenu,
    CandidateTagsDialog,
    CandidateReport,
  },
  data() {
    return {
      opcionesReportDialog: [true, true, true],
      pdfDialogVisible: false,
      page: 1,

      //Tags Menu
      tags_menu: "Etiquetas",

      //Table Title
      table_title: "Evaluación de Perfiles",

      inner_cards_width: window.innerWidth,

      //Text Field Search Placeholder
      search_placeholder: "Escribe y filtra por la palabra que quieras",
      search: "",

      //Table Headers
      email_user: "Email",
      full_name: "Nombre Completo",
      person_org_fit: "Fit Per-Org",
      desired_org_fit: "Fit Des-Org",
      dominant_individual_arq: "Arq. Ind Dominante",
      desired_org_culture: "Cul. Org. Deseada",
      tags: "Etiquetas",
      actions: "Acciones",

      headers: [
        {
          title: "Candidatos",
          align: "center",
          sortable: false,
          key: "index",
        },
      ],
    };
  },

  computed: {
    ...mapState([
      "candidates",
      "tags_list",
      "user_info",
      "candidates_to_show",
      "allowDownloadReport",
    ]),

    pageCount() {
      const pageCount = this.candidates_count / this.itemsPerPage;
      return Math.ceil(pageCount);
    },

    searchCandidate() {
      //const search = this.search.toLowerCase()

      if (!this.search) {
        return this.candidates_to_show;
      } else {
        return this.candidates_to_show.filter((i) => {
          const array_values = Object.values(i);
          const got_it = array_values.filter((y) =>
            y?.toString().toLowerCase().includes(this.search.toLowerCase())
          );
          return got_it.length;
        });
      }
    },
  },

  methods: {
    ...mapMutations([
      "delete_candidate_tag",
      "add_candidate_tag",
      "profiles_evaluation_view_change_step",
      "detailed_view_step",
      "setCultureProfileData",
      "setCultureProfilePredominance",
      "setEngagementData",
      "setEthicalData",
      "setPredValuesData",
      "setFitAreasData",
      "setLeaderData",
    ]),

    show_text() {
      return this.user_info.countPE == 0;
    },

    searchTag(array, tag) {
      return array.indexOf(tag) != -1;
    },

    tags_to_display(array_tags, count) {
      const tags_to_display = [];
      for (let index = 0; index < count; index++) {
        tags_to_display.push(array_tags.at(index));
      }
      return tags_to_display;
    },

    tag_text_to_display(tag_text) {
      if (tag_text.length > 8) {
        return tag_text.substring(0, 8) + "...";
      } else {
        return tag_text;
      }
    },

    async candidate_details(cand) {
      try {
        await this.loadCandidateCultureProfile(cand.token);
        await this.loadCandidateEngagement(cand.token);
        await this.getCandidateReportData(cand.token);
        await this.getCandidateLeadershipData(cand.token);

        this.profiles_evaluation_view_change_step(cand);
      } catch (error) {
        console.error(error);
      }
    },
    openPdfDialog(item) {
      this.currentItem = item;
      this.pdfDialogVisible = true;
    },
    confirmPdfCreation() {
      if (this.currentItem && this.$refs.candidateReport) {
        this.$refs.candidateReport.updateReportData(this.opcionesReportDialog);
        this.pdfDialogVisible = false;
        this.createPDF(this.currentItem);
      }
    },
    createPDF(candidate) {
      this.$refs.candidateReport.generateReport(
        this.user_info.tokens.tokenPE,
        candidate.token
      );
    },

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    async loadCandidateCultureProfile(candToken) {
      try {
        const response =
          await ProfileEvaluationService.loadCandidateCultureProfile(
            this.user_info.tokens.tokenPE,
            candToken,
            "es"
          );
        let data = response.data;

        this.setCultureProfileData(data);

        //Predominance table data seted on predominance variable
        let p = [];

        for (let index = 0; index < data.arqTITULO.length; index++) {
          var obj = {
            title: data.arqTITULO[index],
            value: data.graf2_1_1[index],
          };

          p.push(obj);
        }

        p.sort(function (a, b) {
          return b.value - a.value;
        });

        this.setCultureProfilePredominance(p);
      } catch (error) {
        console.log(error);
      }
    },

    async loadCandidateEngagement(candToken) {
      try {
        const response = await ProfileEvaluationService.loadCandidateEngagement(
          this.user_info.tokens.tokenPE,
          candToken,
          "es"
        );
        let data = response.data;

        this.setEngagementData(data);
      } catch (error) {
        console.log(error);
      }
    },
    async getCandidateReportData(candToken) {
      try {
        const response = await ProfileEvaluationService.getCandidateReportData(
          this.user_info.tokens.tokenPE,
          candToken,
          "es"
        );
        let data = response.data;

        this.setEthicalData(data);
        this.setFitAreasData(data);

        this.setPredValuesData(data);
      } catch (error) {
        console.log(error);
      }
    },

    async getCandidateLeadershipData(candidateToken) {
      try {
        const response =
          await ProfileEvaluationService.getCandidateLeadershipData(
            candidateToken,
            "es"
          );
        let data = response.data;

        this.setLeaderData(data);
      } catch (error) {
        console.log(error);
      }
    },

    //Add Tag

    async setTagToCandidate(cand, tag) {
      let formData = new FormData();
      formData.append("candidato_id", cand.token);
      formData.append("tag_id", tag.id);

      try {
        const response = await TagsService.setTagToCandidate(formData);
        let data = response.data;

        if (data.code == 200) {
          this.add_candidate_tag([cand.index, tag, data.message.id]);
        }
      } catch (error) {
        console.log(error);
      }
    },

    //Remove Tag from Candidate
    async deleteCandidateTag(cand, tag) {
      let formData = new FormData();
      formData.append("id", tag.id);

      try {
        const response = await TagsService.deleteCandidateTag(formData);
        let data = response.data;

        if (data.code == 200) {
          this.delete_candidate_tag([cand, tag]);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.v-data-table .v-table__wrapper > table > thead > tr > td,
.v-data-table .v-table__wrapper > table > thead > tr th,
.v-data-table .v-table__wrapper > table tbody > tr > td,
.v-data-table .v-table__wrapper > table tbody > tr th {
  background-color: transparent !important;
  font-size: 12px;
  color: #585858;
  border-bottom: 1px solid #00c3c0 !important;
}

.v-data-table.headers .v-table__wrapper > table > thead > tr th,
.v-data-table.headers .v-table__wrapper > table tbody > tr th {
  color: #34495e !important;
  font-size: 13px;
  font-weight: bolder !important;
}

.v-data-table .v-table__wrapper > table tbody > tr:hover {
  background: #f4ffff;
}

.select_label_chip {
  background-color: #00c3c0;
  color: white;
}

.header {
  color: #34495e;
}

.data {
  color: #585858;
  font-size: 10px;
}

.custom-line {
  margin-top: -12px;
  margin-bottom: 0;
  border: 1px solid #00c3c0;
  display: block;
}

.chip {
  background-color: #c2c8cf;
}

.table_actions_icons {
  color: #34495e;
}

.filter_icon {
  background-color: #00c3c0;
}

.search_tf {
  font-style: italic;
  font-size: 9px;
}

.tags_to_add {
  background-color: #bdf0ee !important;
}

.list_tag_menu {
  border-bottom: 1px thin black;
}
</style>
