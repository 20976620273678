//View's Name used in route file
export const ROUTES = {
  LOGIN: "/",
  TESTINFO: "testinfo",
  DASHBOARD: "dashboard",
  EDIT_USER: "profile",
  RECOVER_PASSWORD: "recover_password",
  BOX_PARAMETERIZATION: "box-parameterization",
  PARAMETERIZATION: "parameterization",
};
