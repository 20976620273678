import IntegrationsService from "@/services/IntegrationsService";
export default {
  setOrderedHeaders({ commit }, headers) {
    commit("updateOrderedHeaders", headers);
  },
  updateCurrentStep({ commit }, step) {
    commit("setCurrentStep", step);
  },
  resetHeaders({ commit }) {
    commit("resetOrderedHeaders");
  },
  fetchIntegrationKey({ commit, state }) {
    return IntegrationsService.getTeamTailorId(state.user_info.tokens.tokenPE)
      .then((response) => {
        if (response && response.data && response.data.status === 200) {
          commit("setIntegrationKey", response.data.TTtoken);
        } else {
          commit("setIntegrationKey", null);
        }
      })
      .catch((error) => {
        console.error("Error al obtener la Integration Key", error);
        commit("setIntegrationKey", null);
      });
  },
};
