<template>
  <v-tooltip :text="tooltip" location="top">
    <template v-slot:activator="{ props }">
      <v-row :class="smAndDown ? 'd-flex justify-end' : 'd-flex justify-center'">
        <v-col cols="10">
          <v-table
            v-bind="props"
            :density="xlAndUp ? 'default' : 'compact'"
            class="bg-transparent table pa-0 h-75"
          >
            <tbody class="pa-0">
              <tr
                v-for="(item, index) in archetype_predominance"
                :key="item.name"
                class="pa-0 tr"
              >
                <td
                  :class="
                    xlAndUp
                      ? 'text-center testingXL pa-0'
                      : 'text-center testing pa-0'
                  "
                >
                  {{ item.title }}
                </td>
                <td
                  :class="
                    xlAndUp
                      ? 'text-center pa-0 texting2XL'
                      : 'text-center pa-0 texting2'
                  "
                  :style="{
                    background: table_colors.at(index),
                    border: table_colors.at(index),
                  }"
                >
                  {{ index + 1 }}
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
        <v-col cols="1" class="d-flex align-center pa-0">
          <div class="d-flex flex-column" style="width: 100%">
            <v-sheet color="transparent" width="100%" :height="signs_height" class="d-flex align-end justify-center"> 
              <v-icon style="transform: rotate(90deg)" size="15px">mdi mdi-plus</v-icon>
            </v-sheet>
            <v-sheet color="transparent" width="100%" :height="text_height" class="d-flex align-center justify-center"> 
              <h5 class="text-uppercase text-myblue" style="transform: rotate(90deg)">
                {{ table_legend }}
              </h5>
            
            </v-sheet>

            <v-sheet color="transparent" width="100%" :height="signs_height" class="d-flex justify-center"> 
              <v-icon style="transform: rotate(90deg)" size="15px">mdi mdi-minus</v-icon>
            </v-sheet>
          </div>
        </v-col>
      </v-row>
    </template>
  </v-tooltip>
</template>

         <script>
import { mapState } from "vuex";
import { useDisplay } from "vuetify";
import { computed } from "vue";

export default {
  name: "OrgPredominanceTable",

  data: () => ({
    table_colors: [
      "#B1D095",
      "#CADFB8",
      "#E7F7F6",
      "#FFFC8B",
      "#F9D786",
      "#EF857D",
    ],

    tooltip: "",

    table_legend : "",
  }),

  computed: {
    ...mapState(["table_data", "archetype_predominance"]),
  },

  methods: {
    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);
      this.tooltip = texts[50];
      this.table_legend = texts[97];
    },
  },

  created() {
    this.set_texts();
  },

  setup() {
    // Destructure only the keys we want to use
    const { xlAndUp } = useDisplay();
    const { smAndDown } = useDisplay();

    const { name } = useDisplay();

    const charts_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (87 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (87 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }

      return undefined;
    });
    const signs_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "50px";
        case "sm":
          return "50px";
        case "md":
          return (
            (15 *
              ((87 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100)) /
            100
          );
        case "lg":
          return (
            (15 *
              ((87 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100)) /
            100
          );
        case "xl":
          return (
            (15 *
              ((90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100)) /
            100
          );
        case "xxl":
          return (
            (15 *
              ((90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100)) /
            100
          );
      }

      return undefined;
    });

    const text_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "130px";
        case "sm":
          return "130px";
        case "md":
          return (
            (50 *
              ((87 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100)) /
            100
          );
        case "lg":
          return (
            (50 *
              ((87 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100)) /
            100
          );
        case "xl":
          return (
            (50 *
              ((90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100)) /
            100
          );
        case "xxl":
          return (
            (50 *
              ((90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100)) /
            100
          );
      }

      return undefined;
    });

    return {
      xlAndUp,
      charts_sheet_height,
      signs_height,
      text_height,
      smAndDown,
      
    };
  },
};
</script >

<style>
.table,
.tr {
  border: none !important;
  border-spacing: 5px !important;
}

.table {
  font-size: 12px;
}

.testing {
  border: 1px solid #00c3c0 !important;
  width: 100px;
}

.texting2 {
  width: 100px;
}

.testingXL {
  border: 1px solid #00c3c0 !important;
  width: 150px;
}

.texting2XL {
  width: 150px;
}

.text-myblue {
  color: #34495e;
}

</style>