import API from "./API";

export default {
  //TeamTailor
  //Post Integration Key
  postTeamTailorId(CFtoken, TTtoken) {
    let endpoint = "ttailor/actualiza/" + CFtoken + "/" + TTtoken;

    return API().post(endpoint, {
      CFtoken,
      TTtoken,
    });
  },
  getTeamTailorId(CFtoken) {
    let endpoint = "ttailor/consulta/" + CFtoken;
    return API().get(endpoint);
  },
  //
};
