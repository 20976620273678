<script setup>
import { useStore } from "vuex";

import DictionaryForReport from "@/globals/DictionaryForReport";
import { defineProps, computed } from "vue";
import greenCircleRotatedImage from "@/assets/report-images/green-circle-rotated.png";
import CandidateReportKPILegend from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportKPILegend.vue";

const store = useStore();
const Responsibility = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][100];
});
const Loyalty = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][101];
});
const Trust = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][102];
});
const Integrity = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][103];
});
const Transparency = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][104];
});
const Respect = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][105];
});
const ProHigh = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][106];
});
const ProMedium = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][107];
});

let integrityScoreColor = computed(() => {
  return calculateIntegrityScoreColor(props.reportData.integrityIndicator);
});

let integrityScoreBgColor = computed(() => {
  return calculateIntegrityScoreBgColor(props.reportData.integrityIndicator);
});

let chartOptions = computed(() => ({
  chart: {
    height: 350,
    type: "radar",
    toolbar: {
      show: false,
    },
  },

  xaxis: {
    categories: [
      Responsibility.value,
      Loyalty.value,
      Trust.value,
      Integrity.value,
      Transparency.value,
      Respect.value,
    ],
    labels: {
      show: true,
      style: {
        colors: [
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
        ],
        fontSize: "11px",
        fontFamily: "Arial",
      },
    },
  },
  yaxis: {
    show: false,
  },
  legend: {
    show: true,
    offsetY: -24,
    labels: {
      colors: "#34495e",
    },
    fontWeight: 600,
    fontSize: "9px",
    markers: {
      width: 12,
      height: 12,
      strokeWidth: 0,
      strokeColor: "#fff",
      fillColors: ["#34495e"],
      radius: 0,
      onClick: undefined,
    },
  },
  stroke: {
    width: 1,
    colors: ["#34495e"],
  },
  markers: {
    size: 0,
  },
  fill: {
    colors: ["#34495e"],
  },
}));

let ranges = {
  low: { title: "Bajo", text: "Menos de 60" },
  medium: { title: "Estándar", text: "De 60 a 80" },
  high: { title: "Alto", text: "Más de 80" },
};

function calculateIntegrityScoreColor(value) {
  if (value < 60) return "#F7E66B";
  if (value > 80) return "#8ABE66";
  if (value >= 60 && value <= 80) return "#CADFB8";

  return "#F7E66B";
}

function calculateIntegrityScoreBgColor(value) {
  if (value < 60) return "#FBF3BB";
  if (value > 80) return "#C9E1B9";
  if (value >= 60 && value <= 80) return "#E7F0DE";

  return "#FBF3BB";
}

/* function getRowClass(value) {
  if (value == "Alta") return "high";
  else if (value == "Media") return "medium";
  else return "low";
} */
function getRowClass(value) {
  if (value == ProHigh.value) return "high";
  else if (value == ProMedium.value) return "medium";
  else return "low";
}

const props = defineProps({
  reportData: Object,
});

const footerText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][3];
});
const TEthicIndex = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][46];
});
const TGraphethicIndex = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][47];
});

const TKpiEthic = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][48];
});
const TGraphKpiEthic = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][49];
});
const IndexOf = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][50];
});
const ValOf = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][51];
});
const TTable = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][52];
});
const Val = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][53];
});
const Behav = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][54];
});
const Prob = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][55];
});
</script>

<template>
  <div id="appendix1Page1" class="page">
    <div class="page-body">
      <div class="main-container">
        <div class="title">
          {{ TEthicIndex }}
          <strong>{{ reportData.name }}:</strong>
        </div>
        <p class="small-dark-green-field" v-html="TGraphethicIndex"></p>
        <div class="title-s center">{{ TKpiEthic }}</div>
        <div class="kpi center">
          <v-progress-circular
            :rotate="90"
            :size="120"
            :width="8"
            :model-value="reportData.integrityIndicator"
            :color="integrityScoreColor"
            bg-color="transparent"
          >
            <v-avatar size="104" :color="integrityScoreBgColor">
              <div class="kpi-value-number">
                {{ reportData.integrityIndicator }}
              </div>
            </v-avatar>
          </v-progress-circular>
        </div>
        <CandidateReportKPILegend v-bind:ranges="ranges" />

        <p class="small-dark-green-field">
          <strong>{{ IndexOf }} {{ reportData.integrityRecommendation }}</strong
          >:
          {{ reportData.integrityRecommendationText }}
        </p>
        <p class="small-dark-green-field">
          {{ TGraphKpiEthic }}
        </p>
        <div class="values-chart">
          <apexchart
            type="radar"
            :width="450"
            :height="250"
            :options="chartOptions"
            :series="reportData.candidateIntegritySeries"
          ></apexchart>
        </div>
      </div>
    </div>
    <div class="page-footer">
      {{ footerText }}
    </div>
  </div>
  <div id="appendix1Page2" class="page">
    <div class="page-body">
      <img :src="greenCircleRotatedImage" class="page2-green-circle-rotated" />
      <div class="main-container">
        <div class="subtitle">
          {{ ValOf }}
          <strong>{{ reportData.name }}</strong
          >:
        </div>
        <p class="small-dark-green-field" v-html="TTable"></p>
        <v-table v-bind="props">
          <tr>
            <td class="dark-green">{{ Val }}</td>
            <td class="dark-green">{{ Behav }}</td>
            <td class="dark-green">{{ Prob }}</td>
          </tr>
          <tr
            v-for="(value, key) in reportData.mostLikelyBehaviours"
            :key="key"
          >
            <td class="light-green-dt">{{ value.value }}</td>
            <td class="regular-cell">{{ value.behaviour }}</td>
            <td class="regular-cell" :class="getRowClass(value.probability)">
              {{ value.probability }}
            </td>
          </tr>
        </v-table>
      </div>
    </div>
    <div class="page-footer">
      {{ footerText }}
    </div>
  </div>
</template>

<style scoped>
.page2-green-circle-rotated {
  display: block;
  height: 180px;
  position: absolute;
  margin-top: 36px;
  right: 0px;
}

.values-chart {
  width: 450px;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
}
</style>
