<!-- eslint-disable vue/no-mutating-props -->
<template>
  <!--Dialog to edit a tag-->
  <v-dialog
    v-model="dialog"
    activator="parent"
    :width="mdAndUp ? '25%' : '90%'"
  >
    <v-card class="d-flex justify-center text-center">
      <v-container>
        <!--Title Dialog-->
        <v-card-title class="text-uppercase" style="color: #34495e"
          >{{title}}</v-card-title
        >
        <v-card-text>
          <v-form @submit.prevent>
            <!--Input Dialog-->
            <v-row dense class="mt-5">
              <v-col cols="12">
                <v-text-field
                  v-model="tag.texto"
                  required
                  autofocus
                  density="compact"
                  variant="solo"
                  class="newtag_tf"
                  type="text"
                  bg-color="#BDF0EE"
                  :placeholder="newtag_placeholder"
                  :rules="tagRules"
                  hide-details
                  single-line
                  @keydown.enter="save_tag"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#00C3C0">mdi mdi-label-multiple</v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <!--Buttons Dialog-->
            <v-row dense class="mt-8">
              <v-col cols="12">
                <!--Dialog Cancel Button -->
                <v-btn color="error" @click="dialog = false" class="mr-3">
                  {{ cancel_btn }}
                </v-btn>
                <!--Dialog Save Button -->
                <v-btn class="dialog_save_btn" @click.once="save_tag">
                  {{ save_btn }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-container>
    </v-card>
  </v-dialog>

  <v-snackbar
    v-model="snackbar"
    :timeout="2000"
    variant="elevated"
    class="mb-5"
    location="bottom right"
    color="error"
    width="auto"
    transition="scroll-x-reverse-transition"
  >
    <h3 class="text-white">
      <v-icon color="white" class="mr-1" size="30">mdi mdi-check-circle</v-icon>
      {{ snack_message }}
    </h3>
  </v-snackbar>
</template>

<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { mapMutations } from "vuex";
import { useDisplay } from "vuetify";

//API Service
import TagsService from "@/services/TagsService";
import ProfileEvaluationService from "@/services/ProfileEvaluationService";

// Components

export default defineComponent({
  name: "EditTagDialog",
  props: {
    tag: {
      type: Object,
    },
  },
  data() {
    return {
      //Form
      form: false,

      //Snackbar Notification
      snackbar: false,
      snack_message: "",
    
      //New Label Dialog
      dialog: false,

      //Buttons Dialog
      cancel_btn: "",
      save_btn: "",

      title : "",

      tag_model: "",

      //New Label Text Field Placeholder Dialog
      newtag_placeholder: "",

      valid: false,
    
      tagRules: [(v) => !!v || "Nombre de la etiqueta requerido"],


    };
  },
  components: {
    //CandidatesTable,
  },

  computed: {
    ...mapState(["tags_list", "newtag_dialog", "user_info", "candidates"]),

    compTag(){
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.tag_model = this.tag.texto 
    }
  },

  created(){
    this.set_texts();
  },

  methods: {
    ...mapMutations(["setTags_list", "setCandidates", "empty_candidates", "updateCandidateTags"]),

    set_texts (){
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);

      this.newtag_placeholder = texts[75];
      this.cancel_btn = texts[36];
      this.save_btn = texts[32];
      this.title = texts[77];
    },

    //On Update Tag

    save_tag(){
      if(this.tag.texto == ""){
        this.snackbar = true;
        this.snack_message = "Texto de la etiqueta obligatorio"
      }else{
        this.editTag();
      }
    },

    async editTag() {

      let formData = new FormData ();
      formData.append('proyecto_id', this.user_info.tokens.tokenPE);
      formData.append('texto', this.tag.texto);
      formData.append('id', this.tag.id);

      try {
        const response = await TagsService.editTag(formData);
        let data = response.data;

        if(data.code === 200){
          this.dialog = false;
          this.loadAllTags();
          this.loadCandidatesGeneralView();
        }

      } catch (error) {
        console.log(error);
      }
    },

    //Aux
    async loadAllTags() {
      try {
        const response = await TagsService.getAllTags(this.user_info.tokens.tokenPE);
        let data = response.data;

        this.setTags_list(data);

      } catch (error) {
        console.log(error);
      }
    },

    async loadCandidatesGeneralView() {
      this.empty_candidates();
      try {
        const response =
          await ProfileEvaluationService.loadCandidatesGeneralView(
            this.user_info.tokens.tokenPE,
            "es"
          );
        let data = response.data;

        this.setCandidates(data);
      } catch (error) {
        console.log(error);
      }
    },
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();

    return { mdAndUp };
  },
});
</script>

<style scoped>
.label_btn {
  background-color: #00c3c0;
  color: white;
}

.new_label_item {
  font-size: 16px;
  color: #34495e;
  background-color: #bdf0ee;
}

.newtag_tf {
  font-style: italic;
  font-weight: bold;
  background-color: #bdf0ee;
  width: auto;
}

.dialog_save_btn {
  background-color: #34495e;
  color: white;
}
</style>