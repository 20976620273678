<template>
  <div class="text-center mt-n5">
    <apexchart
      type="radar"
      :height="chart_height"
      :options="chartOptions"
      :series="cultureProfileData.series"
    ></apexchart>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { useDisplay } from "vuetify";
import { computed } from "vue";

export default {
  name: "CandidateCultureProfileChart",
  data: () => ({
    series: [
      {
        name: "Predominance Values",
        data: [70, 50, 90, 45, 70, 50],
      },
    ],
    chartOptions: {
      chart: {
        type: "radar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: [],
        labels: {
          show: true,
          style: {
            colors: [
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
            ],
            fontSize: "11px",
            fontFamily: "Arial",
          },
        },
      },
      legend: {
        show: "true",
        position: "top",
      },
      yaxis: {
        show: false,
      },
      stroke: {
        width: 1,
        colors: ["#34495e"],
      },
      markers: {
        size: 0,
      },
      fill: {
        colors: ["#34495e"],
      },
    },
  }),

  computed: {
    ...mapState(["cultureProfileData", "archetypes_titles"]),
  },

  methods: {
    set_texts() {
      let a = sessionStorage.getItem("archetypes");
      this.chartOptions.xaxis.categories = JSON.parse(a);
    },
  },

  created() {
    this.set_texts();
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const chart_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "200%";
        case "sm":
          return "200%";
        case "md":
          return (90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (100 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (100 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (100 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return { chart_height, mdAndUp, xsAndUp };
  },
};
</script>
