<template>
  <div class="mt-n16 bottomp2">
    <apexchart
      type="radar"
      :height="chart_height"
      :options="chartOptions"
      :series="leaderData.series_4"
    ></apexchart>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { useDisplay } from "vuetify";
import { computed } from "vue";

export default {
  name: "LeadershipChart2",

  data: () => ({
    series: [
      {
        name: "Liderazgo transaccional",
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: "radar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: [
          "Cat 1",
          "Cat 2",
          "Cat 3",
          "Cat 4",
          "Cat 5",
          "Cat 6",
          "Cat 7",
          "Cat 8",
          "Cat 9",
          "Cat 10",
          "Cat 11",
          "Cat 12",
        ],
        labels: {
          show: true,
          style: {
            colors: [
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
            ],
            fontSize: "11px",
            fontFamily: "Arial",
          },
        },
      },

      yaxis: {
        show: false,
      },
      legend: {
        show: true,
        offsetY: -24,
        labels: {
          colors: "#34495e",
        },
        fontWeight: 600,
        fontSize: "9px",
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: ["#B39B3E", "#34495e"],
          radius: 0,
          onClick: undefined,
        },
      },
      stroke: {
        width: 1,
        colors: ["#34495e", "#B39B3E"],
      },
      markers: {
        size: 0,
      },
      fill: {
        colors: ["#34495e", "#B39B3E"],
      },
    },
  }),

  computed: {
    ...mapState(["engagementData", "archetypes_titles", "leaderData"]),
  },

  methods: {
    set_texts() {
      let a = sessionStorage.getItem("leader_values2");

      this.chartOptions.xaxis.categories = JSON.parse(a);
    },
  },

  created() {
    this.set_texts();
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const chart_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "200%";
        case "sm":
          return "200%";
        case "md":
          return (120 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (118 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (120 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (120 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return { chart_height, mdAndUp, xsAndUp };
  },
};
</script>
<style scoped>
.bottomp2 {
  margin-bottom: -25%;
}
</style>
