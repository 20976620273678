<script setup>
import { defineProps, computed } from "vue";

import { useStore } from "vuex";

import DictionaryForReport from "@/globals/DictionaryForReport";
import greenCircleRotatedImage from "@/assets/report-images/green-circle-rotated.png";
import CandidateReportKPILegendForLeader from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportKPILegendForLeader.vue";
import Person_LeaderQuadrantForReport from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/Person_LeaderQuadrantForReport.vue";
import CandidateReportLeadershipChart from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportLeadershipChart.vue";
import CandidateReportLeadershipChart2 from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportLeadershipChart2.vue";

/* let integrityScoreColor = computed(() => {
  return calculateIntegrityScoreColor(props.reportData.KPITTransformacional);
}); */
const store = useStore();

let integrityScoreBgColor = computed(() => {
  return calculateIntegrityScoreBgColor(props.reportData.KPITTransformacional);
});
let integrityScoreBgColor2 = computed(() => {
  return calculateIntegrityScoreBgColor2(props.reportData.KPITTransaccional);
});

/* let chartOptions = {
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 2,
      horizontal: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    type: "category",
    categories: [
      "Responsabilidad",
      "Lealtad",
      "Confianza",
      "Integridad",
      "Transparencia",
      "Respeto",
    ],
    tickAmount: 6,
  },
  yaxis: {
    min: 0,
    max: 6,
  },
  tooltip: {
    enabled: false,
  },
  colors: [
    function ({ value }) {
      if (value <= 2) {
        return "#FEFC9A";
      }

      if (value >= 3 && value <= 4) {
        return "#CEDEBB";
      }

      if (value >= 5) {
        return "#B7CF9B";
      }
    },
  ],
}; */

let ranges = {
  low: { title: "Bajo", text: "Menos de 6" },
  medium: { title: "Estándar", text: "De 6 a 8" },
  high: { title: "Alto", text: "De 8 a 10" },
};

/* function calculateIntegrityScoreColor(value) {
  if (value < 6) return "#F7E66B";
  if (value > 8) return "#8ABE66";
  if (value >= 6 && value <= 8) return "#CADFB8";

  return "#F7E66B";
} */

function calculateIntegrityScoreBgColor(value) {
  if (value < 6) return "#FBF3BB";
  if (value > 8) return "#C9E1B9";
  if (value >= 6 && value <= 8) return "#E7F0DE";

  return "#FBF3BB";
}
function calculateIntegrityScoreBgColor2(value) {
  if (value < 6) return "#FBF3BB";
  if (value > 8) return "#C9E1B9";
  if (value >= 6 && value <= 8) return "#E7F0DE";

  return "#FBF3BB";
}
/* 
function getRowClass(value) {
  if (value == "Alta") return "high";
  else if (value == "Media") return "medium";
  else return "low";
} */

const props = defineProps({
  reportData: Object,
});

const footerText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][3];
});

const TindexofLeadership = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][33];
});
const TKpiofLeadership = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][34];
});
const TKpiTransf = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][35];
});
const TKpiTransa = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][36];
});
const TGraph = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][37];
});
const TValLead = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][38];
});
const TGraph2 = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][39];
});
const TValTransfor = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][40];
});
const TValTransac = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][41];
});
</script>

<template>
  <div id="appendix1Page1" class="page">
    <div class="page-body">
      <div class="main-container">
        <div class="title">
          {{ TindexofLeadership }}
          <strong>{{ reportData.name }}: </strong>
        </div>
        <p class="small-dark-green-field" v-html="TKpiofLeadership"></p>
        <div class="kpis-width">
          <div class="kpi">
            <div class="title-s">{{ TKpiTransf }}</div>
            <!--   <v-progress-circular
            :rotate="90"
            :size="120"
            :width="8"
            :model-value="reportData.KPITransformacional"
            :color="integrityScoreColor"
            bg-color="transparent"
          > -->
            <v-avatar size="104" :color="integrityScoreBgColor">
              <div class="kpi-value-number">
                {{ reportData.KPITTransformacional }}
              </div>
            </v-avatar>
            <!--       </v-progress-circular> -->
          </div>
          <div class="kpi">
            <div class="title-s">{{ TKpiTransa }}</div>
            <!--   <v-progress-circular
            :rotate="90"
            :size="120"
            :width="8"
            :model-value="reportData.KPITransformacional"
            :color="integrityScoreColor"
            bg-color="transparent"
          > -->
            <v-avatar size="104" :color="integrityScoreBgColor2">
              <div class="kpi-value-number">
                {{ reportData.KPITTransaccional }}
              </div>
            </v-avatar>
            <!--       </v-progress-circular> -->
          </div>
        </div>

        <CandidateReportKPILegendForLeader v-bind:ranges="ranges" />
        <p class="small-dark-green-field" v-html="TGraph"></p>

        <Person_LeaderQuadrantForReport v-bind:reportData="reportData" />
        <!-- 
        <div class="values-chart">
          <apexchart
            type="bar"
            :width="450"
            :height="250"
            :options="chartOptions"
            :series="reportData.candidateIntegritySeries"
          ></apexchart>
        </div> -->
      </div>
    </div>
    <div class="page-footer">
      {{ footerText }}
    </div>
  </div>
  <div id="appendix1Page2" class="page">
    <div class="page-body">
      <img :src="greenCircleRotatedImage" class="page2-green-circle-rotated" />
      <div class="main-container">
        <div class="subtitle">
          {{ TValLead }}
          <strong>{{ reportData.name }}</strong
          >:
        </div>
        <p class="small-dark-green-field" v-html="TGraph2"></p>
        <!--  centrar ambis header -->
        <div class="title-s center">{{ TValTransfor }}</div>
        <CandidateReportLeadershipChart v-bind:reportData="reportData" />
        <div class="title-s center mt-n9">{{ TValTransac }}</div>
        <CandidateReportLeadershipChart2 v-bind:reportData="reportData" />
      </div>
    </div>
    <div class="page-footer">
      {{ footerText }}
    </div>
  </div>
</template>

<style scoped>
.page2-green-circle-rotated {
  display: block;
  height: 180px;
  position: absolute;
  margin-top: 36px;
  right: 0px;
}

.values-chart {
  width: 450px;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
}
.kpis-width {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
</style>
