<template>
  <div class="cardwhitePVCAD">
    <h3 class="text-uppercase" style="color: #34495e">
      Mentalidades predominantes
    </h3>
    <v-card
      elevation="0"
      :class="mdAndUp ? 'card ' : 'mt-10 mb-10'"
      color="transparent"
      :height="card_height"
    >
      <v-row
        dense
        no-gutters
        :class="mdAndUp ? 'text-justify' : 'text-justify mx-3'"
        style="height: 60%"
      >
        <v-col cols="12" md="12">
          <div>
            <v-row dense :class="mdAndUp ? 'd-flex justify-space-between' : ''">
              <v-col cols="12" md="6" :class="mdAndUp ? '' : 'mb-3'">
                <div :class="mdAndUp ? 'div' : ''">
                  <h4 class="aspects">
                    {{ cultureProfileData.arch_titles_PRED[0] }}
                  </h4>
                  <p class="description text-justify">
                    {{ cultureProfileData.arch_texts[0] }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <h4 class="aspects">
                  {{ cultureProfileData.arch_titles_PRED[1] }}
                </h4>
                <p class="description text-justify">
                  {{ cultureProfileData.arch_texts[1] }}
                </p>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { useDisplay } from "vuetify";
import { computed } from "vue";

export default {
  name: "CultureAspectsDescription",

  computed: {
    ...mapState(["cultureProfileData"]),
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();

    const { name } = useDisplay();

    const card_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (55 * ((80 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (20 * ((80 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (20 * ((80 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (55 * ((80 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }

      return undefined;
    });

    return { card_height, mdAndUp };
  },
};
</script>

<style>
.description {
  color: #585858;
  font-size: 12px;
}

.aspects {
  color: #34495e;
}

.div {
  width: 98%;
}

.card::-webkit-scrollbar {
  -webkit-appearance: none;
}

.card::-webkit-scrollbar:vertical {
  width: 10px;
}

.card::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none;
}

.card::-webkit-scrollbar:horizontal {
  height: 10px;
}

.card::-webkit-scrollbar-thumb {
  background-color: #00c3c0;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.card::-webkit-scrollbar-track {
  border-radius: 10px;
}
.cardwhitePVCAD {
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #efefef;
  margin-left: 2.3%;
  margin-top: 0.5%;
  max-width: 94.9%;
  padding-bottom: 1%;
  padding-left: 2%;
  padding-right: 2%;
}
</style>
