<script setup>
import { defineProps, computed } from "vue";

import { useStore } from "vuex";

import DictionaryForReport from "@/globals/DictionaryForReport";
import horizontalLinesImage from "@/assets/report-images/horizontal-lines.png";

import CandidateReportArchetypeAffinityTable from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportArchetypeAffinityTable.vue";
import CandidateReportCultureProfileChart from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportCultureProfileChart.vue";

defineProps({
  reportData: Object,
});

const store = useStore();

const ExecutiveSummary = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][9];
});

const footerText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][3];
});

const MentOF = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][10];
});

const FirstMent = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][11];
});
const PredValueTitle = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][12];
});

const SecondMent = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][13];
});
</script>

<template>
  <div id="executiveSummary" class="page">
    <div class="page-body">
      <img :src="horizontalLinesImage" class="page3-horizontal-lines" />

      <div class="main-container">
        <div>
          <div class="title">{{ ExecutiveSummary }}</div>
          <div class="subtitle mt-2">
            {{ MentOF }} <strong>{{ reportData.name }}</strong>
          </div>
        </div>

        <div class="charts">
          <div class="chart">
            <div style="margin-top: -20px">
              <CandidateReportCultureProfileChart
                width="240px"
                height="220px"
                v-bind:series="reportData.archValuesChartSeries"
              />
            </div>
          </div>
          <div class="chart">
            <CandidateReportArchetypeAffinityTable
              v-bind:reportData="reportData"
              height="175px"
            />
          </div>
        </div>

        <table class="arch-predominant">
          <tr>
            <td class="dark-green">
              {{ FirstMent }}
              <strong>{{ reportData.archMax1 }}</strong>
            </td>
            <td class="light-green-lt" style="min-width: 190px">
              {{ PredValueTitle }}
            </td>
          </tr>
          <tr>
            <td class="regular-cell">{{ reportData.archMax1TextSummary }}</td>
            <td rowspan="3" class="regular-cell">
              <ul>
                <li v-for="(value, key) in reportData.valuesTitle" :key="key">
                  {{ value }}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="dark-green">
              {{ SecondMent }}
              <strong>{{ reportData.archMax2 }}</strong>
            </td>
          </tr>
          <tr>
            <td class="regular-cell">{{ reportData.archMax2TextSummary }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="page-footer">
      {{ footerText }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page3-horizontal-lines {
  width: 312px;
  position: absolute;
  margin-top: 0px;
  right: 0px;
}

.charts {
  display: flex;
  gap: 110px;
  justify-content: space-around;

  .chart {
    min-width: 200px;
  }
}

.kpis-and-legend {
  display: flex;
  justify-content: space-between;
  gap: 40px;

  .kpi-card {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .line {
      width: 2px;
      background-color: #1f3863;
      height: 100%;
      transform: rotate(-12deg);
      margin-right: 12px;
    }

    .text {
      font-size: calc(9px * 1.333);
      color: #1f3863;

      > strong {
        font-weight: bold;
        color: #47b7a8;
      }
    }

    .kpi-value {
      max-width: 80px;
      width: 80px;
      height: 80px;

      .kpi-value-number {
        font-weight: bold;
        color: #1f3863;
        font-family: Poppins, sans-serif;
      }
    }
  }
}

table.arch-predominant {
  margin-top: -24px;
}

.v-progress-circular > svg {
  transform: rotate(calc(0deg)) !important;
}
</style>
