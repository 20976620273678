<template>
  <div>
    <apexchart
      type="radar"
      :height="chartHeight"
      :options="chartOptions"
      :series="chartSeries"
    ></apexchart>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
import { useStore } from "vuex";

import DictionaryForReport from "@/globals/DictionaryForReport";
const store = useStore();

const ResultOrient = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][85];
});
const Formality = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][86];
});
const Excellence = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][87];
});
const Coordination = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][88];
});
const AnalyticalFocus = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][89];
});
const Stability = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][90];
});
const Loyalty = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][91];
});
const Pragmatism = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][92];
});
const Responsibility = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][93];
});
const Perseverance = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][94];
});

const Commitment = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][95];
});
const Efficiency = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][96];
});

const props = defineProps({
  reportData: Object,
});

const chartHeight = computed(() => {
  return "350"; // Ajusta esta altura según sea necesario
});

// Opciones básicas para el gráfico, puedes ajustar esto según sea necesario
const chartOptions = computed(() => ({
  chart: {
    height: 390,
    type: "radar",
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    categories: [
      ResultOrient.value,
      Formality.value,
      Excellence.value,
      Coordination.value,
      AnalyticalFocus.value,
      Stability.value,
      Loyalty.value,
      Pragmatism.value,
      Responsibility.value,
      Perseverance.value,
      Commitment.value,
      Efficiency.value,
    ],
    labels: {
      show: true,
      style: {
        colors: [
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
        ],
        fontSize: "12px",
        fontFamily: "Arial",
      },
    },
  },
  yaxis: {
    show: false,
  },
  legend: {
    show: true,
    offsetY: -24,
    labels: {
      colors: "#34495e",
    },
    fontWeight: 600,
    fontSize: "9px",
    markers: {
      width: 12,
      height: 12,
      strokeWidth: 0,
      strokeColor: "#fff",
      fillColors: ["#B39B3E", "#34495e"],
      radius: 0,
      onClick: undefined,
    },
  },
  stroke: {
    width: 1,
    colors: ["#34495e", "#B39B3E"],
  },
  markers: {
    size: 0,
  },
  fill: {
    colors: ["#34495e", "#B39B3E"],
  },
}));

// Extrae las series de `reportData.series_44`
const chartSeries = computed(() => {
  // Asegúrate de que `reportData` y `series_44` existen antes de intentar acceder a ellos
  if (props.reportData && props.reportData.series_44) {
    return props.reportData.series_44;
  }
  // Retorna un arreglo vacío o alguna serie predeterminada si no hay datos disponibles
  return [];
});
</script>
