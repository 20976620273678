<template>
  <div :class="mdAndUp ? 'd-flex flex-row' : ''">
    <!--Left Sheet-->
    <v-sheet
      color="transparent"
      :height="left_sheet_height"
      :width="left_sheet_width"
    >
      <!--Buttons Section-->
      <v-sheet
        height="100%"
        width="100%"
        :color="mdAndUp ? 'white' : 'transparent'"
        :class="mdAndUp ? 'd-flex align-center' : 'd-flex align-center mt-10'"
        :elevation="mdAndUp ? 2 : 0"
      >
        <ProfilesEvaluationButtonsSection />
      </v-sheet>
    </v-sheet>

    <!--Right Sheet-->
    <v-sheet
      class=""
      color="transparent"
      :height="left_sheet_height"
      :width="right_sheet_width"
    >
      <v-sheet :height="right_sheet_height" width="100%" color="transparent">
        <!--Table-->
        <v-row dense>
          <v-col cols="12">
            <div>
              <CandidatesDesktopTableOfIdealParam
                :table_top_sheet_height="table_top_sheet_height"
                :table_sheet_height="table_sheet_height"
                :paramCandidates="param_candidates"
              />
            </div>
          </v-col>
        </v-row>
      </v-sheet>

      <!--Buttons Section for Mobile Responsive-->
      <v-sheet
        class="hidden-md-and-up"
        color="transparent"
        :height="left_sheet_height"
        :width="left_sheet_width"
      >
        <v-sheet
          height="100%"
          width="100%"
          color="transparent"
          class="d-flex align-center hidden-md-and-up"
          elevation="0"
        >
          <ProfilesEvaluationButtonsSectionMobile />
        </v-sheet>
      </v-sheet>

      <!--Footer-->
      <v-sheet
        :height="footer_height"
        width="100%"
        color="transparent"
        class="d-flex align-center"
      >
        <v-row wrap no-gutters :class="mdAndUp ? '' : 'mx-3 mb-3'">
          <v-col cols="12" md="12">
            <div class="text-justify footer bg-transparent w-100 h-100">
              <p :class="mdAndUp ? 'mx-6' : ''">{{ footer }}</p>
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-sheet>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapState, mapMutations } from "vuex";
import { computed } from "vue";
import { useDisplay } from "vuetify";

//API Services
import ProfileEvaluationService from "@/services/ProfileEvaluationService";

// Components
import ProfilesEvaluationButtonsSection from "@/components/DashboardComponents/ProfilesEvaluation/ProfilesEvaluationButtonsSection.vue";
import ProfilesEvaluationButtonsSectionMobile from "@/components/DashboardComponents/ProfilesEvaluation/ProfilesEvaluationButtonsSectionMobile.vue";
import CandidatesDesktopTableOfIdealParam from "@/components/DashboardComponents/ProfilesEvaluation/CandidatesDesktopTableOfIdealParam.vue";

export default defineComponent({
  name: "CandidatesGeneralView",
  data() {
    return {
      table_title: "Evaluación de Perfiles",
      height: window.innerHeight,
      label: "Etiquetas",
      search_placeholder: "Escribe y filtra por la palabra que quieras",
      filter: "",
      footer: "",
    };
  },
  components: {
    ProfilesEvaluationButtonsSection,
    CandidatesDesktopTableOfIdealParam,
    ProfilesEvaluationButtonsSectionMobile,
  },
  computed: {
    ...mapState([
      "user_info",
      "detailed_view_step",
      "search_table",
      "param_candidates",
      "search",
    ]),
  },
  methods: {
    ...mapMutations(["setParametizationTable"]),
    async getListofIDealParams() {
      try {
        const response = await ProfileEvaluationService.getListofIDealParams(
          this.user_info.tokens.tokenPE
        );
        let data = response.data;
        this.setParametizationTable(data);
      } catch (error) {
        console.log(error);
      }
    },
    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);
      this.footer = texts[62];
    },
  },
  async created() {
    this.getListofIDealParams();
    this.set_texts();
  },
  setup() {
    const { mdAndUp } = useDisplay();
    const { lgAndUp } = useDisplay();
    const { xsAndUp } = useDisplay();
    const { name } = useDisplay();

    const left_sheet_height = computed(() => {
      switch (name.value) {
        case "xs":
        case "sm":
          return "auto";
        case "md":
        case "lg":
        case "xl":
        case "xxl":
          return (92 * window.innerHeight) / 100;
        default:
          return undefined;
      }
    });
    const left_sheet_width = computed(() => {
      switch (name.value) {
        case "xs":
        case "sm":
          return "auto";
        case "md":
        case "lg":
        case "xl":
        case "xxl":
          return (18 * window.innerWidth) / 100;
        default:
          return undefined;
      }
    });
    const right_sheet_width = computed(() => {
      switch (name.value) {
        case "xs":
        case "sm":
          return "auto";
        case "md":
        case "lg":
        case "xl":
        case "xxl":
          return (82 * window.innerWidth) / 100;
        default:
          return undefined;
      }
    });
    const right_sheet_height = computed(() => {
      switch (name.value) {
        case "xs":
        case "sm":
          return "auto";
        case "md":
        case "lg":
        case "xl":
        case "xxl":
          return (94 * ((92 * window.innerHeight) / 100)) / 100;
        default:
          return undefined;
      }
    });
    const table_top_sheet_height = computed(() => {
      switch (name.value) {
        case "xs":
        case "sm":
          return "auto";
        case "md":
          return (10 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (10 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (13 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (12 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        default:
          return undefined;
      }
    });
    const table_sheet_height = computed(() => {
      switch (name.value) {
        case "xs":
        case "sm":
          return "auto";
        case "md":
          return (
            (90 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100 - 20
          );
        case "lg":
          return (
            (77 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100 - 20
          );
        case "xl":
          return (
            (83 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100 - 20
          );
        case "xxl":
          return (
            (84 * ((94 * ((92 * window.innerHeight) / 100)) / 100)) / 100 - 20
          );
        default:
          return undefined;
      }
    });
    const footer_height = computed(() => {
      switch (name.value) {
        case "xs":
        case "sm":
          return "auto";
        case "md":
        case "lg":
        case "xl":
        case "xxl":
          return (6 * ((92 * window.innerHeight) / 100)) / 100;
        default:
          return undefined;
      }
    });
    const itemsPerPage = computed(() => {
      switch (name.value) {
        case "md":
          return 8;
        case "lg":
          return 10;
        case "xl":
          return 15;
        case "xxl":
          return 20;
        default:
          return undefined;
      }
    });

    return {
      left_sheet_height,
      left_sheet_width,
      right_sheet_height,
      table_top_sheet_height,
      table_sheet_height,
      right_sheet_width,
      footer_height,
      itemsPerPage,
      mdAndUp,
      lgAndUp,
      xsAndUp,
    };
  },
  watch: {
    detailed_view_step(newVal) {
      if (newVal === 4) {
        this.getListofIDealParams();
      }
    },
  },
});
</script>

<style scoped>
.search_tf {
  font-style: italic;
}

.container {
  background-color: #fbfbfb;
}

.footer {
  font-size: 60%;
  font-style: italic;
  color: #585858;
}

.filter_icon {
  background-color: #00c3c0;
}

.testing {
  height: 100% !important;
}
</style>
